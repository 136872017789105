import { ActionIcon, Tooltip } from '@mantine/core'
import { Link } from 'wouter'

import { FaIcon } from '@components/FaIcon'
import { useMst } from '@hooks'

import { snapshotsPath } from './routing'

export const SnapshotLandIcon = ({ scriptId }: { scriptId: string }) => {
  const { user, location, view } = useMst()

  const href = snapshotsPath(scriptId, { view: user.prefs.snapshotTab })

  const handleClick = async () => {
    view.explorerState.setLastScriptPath(location.pathname)
    view.snapshotLand.trackSnapshotEvent('ENTER_SNAPSHOT_LAND')
  }

  return (
    <Tooltip label="Snapshot history">
      <ActionIcon
        component={Link}
        href={href}
        onClick={handleClick}
        color="dark.9"
      >
        <FaIcon icon="fa-clock-rotate-left" />
      </ActionIcon>
    </Tooltip>
  )
}
