import { Link } from 'wouter'

import {
  isSnapshotSelectable,
  isSnapshotSelected,
} from '@components/SnapshotLand/helpers'
import { SnapshotCard } from '@components/SnapshotLand/SnapshotCard'
import { useMst } from '@hooks'

const CANNED_SCRIPT_DATA = {
  id: 'current',
  name: 'Current script',
  scriptId: '',
  version: 0,
  createdBy: '',
  creatorName: '',
  // to suppress author
  autoSave: 'backup',
}

export const CurrentScriptCard = ({
  icon,
  onClose,
  sticky = false,
}: {
  icon?: React.ReactNode
  onClose?: () => void
  sticky?: boolean
}) => {
  const {
    currentView,
    scriptFetchedAt,
    selectSnapshotUrl,
    snap1,
    snap2,
    trackSnapshotEvent,
  } = useMst().view.snapshotLand

  const snapshotId = CANNED_SCRIPT_DATA.id
  const createdAt = scriptFetchedAt?.toISOString() ?? new Date().toISOString()
  const opts = { snap1, snap2, currentView, snapshotId }

  const card = (
    <SnapshotCard
      selected={isSnapshotSelected(opts)}
      data={{ ...CANNED_SCRIPT_DATA, createdAt }}
      variant="current-script"
      sticky={sticky}
      icon={icon}
      onClose={onClose}
    />
  )

  if (isSnapshotSelectable(opts)) {
    return (
      <Link
        asChild
        href={selectSnapshotUrl(snapshotId)}
        onClick={() => trackSnapshotEvent('SELECT_SNAPSHOT')}
      >
        {card}
      </Link>
    )
  }

  return card
}
