import {
  Diff,
  DIFF_DELETE,
  DIFF_EQUAL,
  DIFF_INSERT,
  diff_match_patch as DiffMatchPatch,
} from 'diff-match-patch'
import { Fragment, Node as PmNode } from 'prosemirror-model'

import { NodeTypeKey } from '@showrunner/codex'

import {
  buildSideBySide,
  filterLines,
  isOmitted,
  isUnchanged,
} from './sideBySide'

type DiffableBlockInfo = {
  blockType: NodeTypeKey
  text: string
  position: number
  id: string | null
}

// Returns an array of diff tuples. Each tuple contains a diff type (removed,
// added, no change) and the text, cleaned to human-legible changes
//
// `diff_main` in this library switches from a word-based diff to a
// character-based diff when there are more than 100 characters in the string.
// To combat this, we force the library to always use a word-wise comparison
export const getRawDiff = (left: string, right: string): Diff[] => {
  const differ = new DiffMatchPatch()
  const wordsToLines = differ.diff_charsToLines_
  const { chars1, chars2, lineArray } = linesToWords(left, right)
  const diffs = differ.diff_main(chars1, chars2, false)
  wordsToLines(diffs, lineArray)
  differ.diff_cleanupSemantic(diffs)
  return diffs
}

// This modifies the diff_linesToChars function to split on spaces in addition
// to newlines:
//
// https://github.com/google/diff-match-patch/blob/62f2e689f498f9c92dbc588c58750addec9b1654/javascript/diff_match_patch_uncompressed.js#L466
//
function linesToWords(text1: string, text2: string) {
  const lineArray: string[] = [] // e.g. lineArray[4] == 'Hello\n'
  const lineHash: Record<string, number> = {} // e.g. lineHash['Hello\n'] == 4

  // '\x00' is a valid character, but various debuggers don't like it.
  // So we'll insert a junk entry to avoid generating a null character.
  lineArray[0] = ''

  function diff_wordsToCharsMunge_(text: string): string {
    let chars = ''
    // Walk the text, pulling out a substring for each line.
    // text.split('\n') would would temporarily double our memory footprint.
    // Modifying text would create many large strings to garbage collect.
    let lineStart = 0
    let lineEnd = -1
    // Keeping our own length variable is faster than looking it up.
    let lineArrayLength = lineArray.length
    while (lineEnd < text.length - 1) {
      const match = /[ \n]/.exec(text.substring(lineStart))
      if (match) {
        lineEnd = lineStart + match.index
      } else {
        lineEnd = text.length - 1
      }
      let line = text.substring(lineStart, lineEnd + 1)

      if (lineHash[line] !== undefined) {
        chars += String.fromCharCode(lineHash[line])
      } else {
        if (lineArrayLength === maxLines) {
          // Bail out at 65535 because
          // String.fromCharCode(65536) == String.fromCharCode(0)
          line = text.substring(lineStart)
          lineEnd = text.length
        }
        chars += String.fromCharCode(lineArrayLength)
        lineHash[line] = lineArrayLength
        lineArray[lineArrayLength++] = line
      }
      lineStart = lineEnd + 1
    }
    return chars
  }

  // Allocate 2/3rds of the space for text1, the rest for text2.
  let maxLines = 40000
  const chars1 = diff_wordsToCharsMunge_(text1)
  maxLines = 65535
  const chars2 = diff_wordsToCharsMunge_(text2)
  return { chars1: chars1, chars2: chars2, lineArray: lineArray }
}

// Find all the blockText nodes in a prose doc and build a flat
// array of them, preserving their positions
const fragmentToDiffableBlockInfo = (doc: Fragment) => {
  const result: DiffableBlockInfo[] = []
  doc.descendants((node, pos) => {
    const isTextblock = node.isTextblock
    if (!isTextblock) {
      return
    }
    const id = typeof node.attrs.id === 'string' ? node.attrs.id : null

    result.push({
      blockType: node.type.name as NodeTypeKey,
      text: node.textContent,
      // pos is the beginning of the block but the inline text node is inset
      // by 1
      position: pos + 1,
      id,
    })
  })

  return result
}

export const extractSlugFragment = (
  doc: PmNode,
  slugId: string,
): Fragment | null => {
  let startPosition = -1
  let endPosition: number | undefined = undefined
  doc.descendants((node, pos) => {
    if (startPosition < 0) {
      if (node.type.name === 'slug' && node.attrs.id === slugId) {
        startPosition = pos
      }
    } else if (endPosition === undefined) {
      if (node.type.name === 'slug') {
        endPosition = pos
      }
    }
  })
  if (startPosition > -1) {
    const slice = doc.slice(startPosition, endPosition)
    return slice.content
  }

  return null
}

// take an array of block infos and create a string concatenating all the text info
// and adding newline characters wherever there are gaps. The indexes of the string
// should correspond to the positions in the DiffableBlockInfo items
export const toDiffableText = (
  blockInfoList: DiffableBlockInfo[],
  preservePmPositions?: boolean,
): string => {
  const characters: string[] = []

  const addBlockInfo = (bi: DiffableBlockInfo) => {
    const startPosition = bi.position
    // if we are preserving PM positions, we insert as many \n characters
    // as needed to pad the count
    if (preservePmPositions && startPosition > characters.length) {
      const newlinesToAdd = startPosition - characters.length
      for (let i = 0; i < newlinesToAdd; i++) {
        characters.push('\n')
      }
    }
    characters.push(...bi.text.split(''))
    // if we are not preserving PM positions, we want each block to terminate in a
    // newline
    if (!preservePmPositions) {
      characters.push('\n')
    }
  }
  blockInfoList.forEach(addBlockInfo)
  return characters.join('')
}

type EnrichedDiff = {
  text: string
  diffType: Diff[0]
  leftPosition: number
  rightPosition: number
}

type DiffRange = [startPosition: number, endPosition: number]

const buildEnrichedDiff = (left: string, right: string): EnrichedDiff[] => {
  const rawDiff = getRawDiff(left, right)
  const result: EnrichedDiff[] = []
  let leftPosition = 0
  let rightPosition = 0
  rawDiff.forEach(([diffType, text]) => {
    result.push({
      text,
      diffType,
      leftPosition,
      rightPosition,
    })
    if (diffType !== DIFF_INSERT) {
      leftPosition += text.length
    }
    if (diffType !== DIFF_DELETE) {
      rightPosition += text.length
    }
  })

  return result
}

export class FragmentDiff {
  private readonly _leftBlockInfo: DiffableBlockInfo[]
  private readonly _rightBlockInfo: DiffableBlockInfo[]

  private _pmAwareDiff: Diff[] | null = null
  private _pmObliviousDiff: Diff[] | null = null

  private _pmAwareDiffs: EnrichedDiff[] | null = null
  private _pmObliviousDiffs: EnrichedDiff[] | null = null

  constructor({ left, right }: { left: Fragment; right: Fragment }) {
    this._leftBlockInfo = fragmentToDiffableBlockInfo(left)
    this._rightBlockInfo = fragmentToDiffableBlockInfo(right)
  }

  // lazy getter
  get pmAwareDiff(): Diff[] {
    if (this._pmAwareDiff) {
      return this._pmAwareDiff
    }
    const leftText = toDiffableText(this._leftBlockInfo, true)
    const rightText = toDiffableText(this._rightBlockInfo, true)
    this._pmAwareDiff = getRawDiff(leftText, rightText)
    return this._pmAwareDiff
  }

  get pmObliviousDiff(): Diff[] {
    if (this._pmObliviousDiff) {
      return this._pmObliviousDiff
    }
    const leftText = toDiffableText(this._leftBlockInfo, false)
    const rightText = toDiffableText(this._rightBlockInfo, false)
    this._pmObliviousDiff = getRawDiff(leftText, rightText)
    return this._pmObliviousDiff
  }

  get pmAwareDiffs(): EnrichedDiff[] {
    if (this._pmAwareDiffs) {
      return this._pmAwareDiffs
    }
    const leftText = toDiffableText(this._leftBlockInfo, true)
    const rightText = toDiffableText(this._rightBlockInfo, true)
    this._pmAwareDiffs = buildEnrichedDiff(leftText, rightText)
    return this._pmAwareDiffs
  }

  get pmObliviousDiffs(): EnrichedDiff[] {
    if (this._pmObliviousDiffs) {
      return this._pmObliviousDiffs
    }
    const leftText = toDiffableText(this._leftBlockInfo, false)
    const rightText = toDiffableText(this._rightBlockInfo, false)
    this._pmObliviousDiffs = buildEnrichedDiff(leftText, rightText)
    return this._pmObliviousDiffs
  }

  get identical(): boolean {
    return this.getSideBySideDiff(null).every(
      (line) => isOmitted(line) || isUnchanged(line),
    )
  }

  // for one side or the other, return an array of position ranges that are changed. This is
  // the position data needed to generate prosemirror decorations showing one of the two docs
  getChangePositionsForSide(side: 'left' | 'right'): {
    changed: DiffRange[]
    removed: number[]
  } {
    const result: { changed: DiffRange[]; removed: number[] } = {
      changed: [],
      removed: [],
    }

    this.pmAwareDiffs.forEach(
      ({ diffType, text, leftPosition, rightPosition }) => {
        // skip the unchanged parts
        if (diffType === DIFF_EQUAL) {
          return
        }
        const startPosition = side === 'left' ? leftPosition : rightPosition
        const textIsFromThisSide =
          (side === 'left' && diffType === DIFF_DELETE) ||
          (side === 'right' && diffType === DIFF_INSERT)

        if (textIsFromThisSide) {
          result.changed.push([startPosition, startPosition + text.length])
        } else {
          result.removed.push(startPosition)
        }
      },
    )

    return result
  }

  get sideBySide() {
    return buildSideBySide(this.pmObliviousDiffs)
  }

  getSideBySideDiff(linesOfContext?: number | null) {
    const lines = buildSideBySide(this.pmObliviousDiffs)
    if (typeof linesOfContext === 'number') {
      return filterLines(lines, linesOfContext)
    }
    return lines
  }
}
