import { Group, Table, TableData, Text } from '@mantine/core'

import { SmartFaIcon } from '@components/FaIcon'
import { NavAnchor } from '@components/NavAnchor'
import { useStaffFormats } from '@hooks'

import { StaffScriptFormatSummary } from '../helpers'
import { CopyButton } from '../index'

const FormatsTable = ({
  caption,
  formats,
}: {
  caption: string
  formats: StaffScriptFormatSummary[]
}) => {
  const tableData: TableData = {
    caption,
    head: ['name', 'id', 'icon', 'replaced by', 'workspace assignments'],
    body: formats
      .sort((a, b) => a.id.localeCompare(b.id))
      .map((f) => [
        f.name,
        <Group key={f.id + 'format-id'}>
          <NavAnchor href={`/formats/${f.id}`}>
            <Text fz="14px">{f.id}</Text>
          </NavAnchor>
          <CopyButton text={f.id} />
        </Group>,
        <SmartFaIcon
          key={f.id + 'format-icon'}
          icon={f.icon}
          fallback={<div>icon</div>}
        />,
        f.replacedById,
        f.workspaceCount,
      ]),
  }

  return <Table data={tableData} captionSide="top" />
}

export const FormatsList = () => {
  const { formatsQuery } = useStaffFormats()
  const { isPending, isError, data } = formatsQuery

  if (isPending) return <div>loading formats</div>
  if (isError) return <div>error fetching formats</div>

  const activeFormats: StaffScriptFormatSummary[] = []
  const inactiveFormats: StaffScriptFormatSummary[] = []

  data.forEach((f) =>
    f.active ? activeFormats.push(f) : inactiveFormats.push(f),
  )

  return (
    <>
      <FormatsTable caption="Active formats" formats={activeFormats} />
      <FormatsTable caption="Inactive formats" formats={inactiveFormats} />
    </>
  )
}
