import { inject, type RouteParams } from 'regexparam'

import { NavAnchorHref } from '@components/NavAnchor'

import { ROUTE_PATTERNS } from './pathConfigs'

type ValidPaths = ValueOf<typeof ROUTE_PATTERNS>

// Not clear if this is needed, but we include it to keep compatibility with the
// path-to-regex package
function cleanParams<T extends string>(params: RouteParams<T>) {
  const cleanParams: Record<string, string> = {}

  for (const [key, value] of Object.entries(params)) {
    if (value) cleanParams[key] = encodeURIComponent(value)
  }

  return cleanParams as RouteParams<T>
}

export const pathTo = <T extends ValidPaths>(
  pattern: T,
  params?: RouteParams<T>,
  options?: {
    queryParams?: { [key: string]: string | number | boolean }
  },
): NavAnchorHref => {
  const path = params ? inject(pattern, cleanParams(params)) : pattern

  if (options?.queryParams && Object.keys(options.queryParams).length > 0) {
    const searchParams = new URLSearchParams()

    for (const [key, val] of Object.entries(options.queryParams)) {
      searchParams.append(key, String(val))
    }

    return (path + '?' + searchParams.toString()) as NavAnchorHref
  }
  // too hard to figure out how to get typescript to know that
  // our internal paths all start with /
  return path as NavAnchorHref
}

export const absolutePathTo = <T extends ValueOf<typeof ROUTE_PATTERNS>>(
  pattern: T,
  params?: RouteParams<T>,
  options?: {
    queryParams?: { [key: string]: string | number | boolean }
  },
) => {
  return window.location.origin + pathTo(pattern, params, options)
}
