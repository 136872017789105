import React from 'react'

import { nanoid } from 'nanoid'
import { BaseLocationHook, Router } from 'wouter'
import { useBrowserLocation } from 'wouter/use-browser-location'

import { noop } from '@util'

import {
  BeforeRouteChangeHandler,
  RouteChangeArgs,
  RouteChangeHandler,
} from './types'

export const WallabyRouterContext = React.createContext<{
  registerUnloadHandler: (handler: RouteChangeHandler) => () => void
  registerBeforeUnloadHandler: (handler: BeforeRouteChangeHandler) => () => void
  checkUnload: BeforeRouteChangeHandler
  beforeExit: RouteChangeHandler
}>({
  registerUnloadHandler: () => noop,
  registerBeforeUnloadHandler: () => noop,
  checkUnload: () => Promise.resolve({ cancel: false }),
  beforeExit: noop,
})

// this hook is ONLY used by the router itself, not exported.
const useWallabyLocationHook: BaseLocationHook = () => {
  const ctx = React.useContext(WallabyRouterContext)
  const [currentLocation, setLocation] = useBrowserLocation()

  const handleLocationChange = async (
    location: string,
    options?: { replace?: boolean },
  ) => {
    const args = { from: currentLocation, to: location }
    const { cancel } = await ctx.checkUnload(args)
    if (!cancel) {
      ctx.beforeExit({ from: currentLocation, to: location })
      setLocation(location, options)
    }
  }

  return [currentLocation, handleLocationChange]
}

export const WallabyRouter = ({ children }: { children: React.ReactNode }) => {
  // registry of current unload handlers
  const { current: beforeUnloadHandlers } = React.useRef<{
    [key: string]: BeforeRouteChangeHandler
  }>({})

  const { current: unloadHandlers } = React.useRef<{
    [key: string]: RouteChangeHandler
  }>({})

  const registerUnloadHandler = (value: RouteChangeHandler) => {
    const id = nanoid()
    unloadHandlers[id] = value
    return () => delete unloadHandlers[id]
  }

  const registerBeforeUnloadHandler = (value: BeforeRouteChangeHandler) => {
    const id = nanoid()
    beforeUnloadHandlers[id] = value
    return () => delete beforeUnloadHandlers[id]
  }

  const beforeExit = (args: RouteChangeArgs) => {
    Object.values(unloadHandlers).forEach((fn) => {
      fn(args)
    })
  }

  const checkUnload = async (args: RouteChangeArgs) => {
    const checks = Object.values(beforeUnloadHandlers)
    // we iterate through each of the checks in random order. If any
    // one of them aborts, skip the rest and block the transition
    for (let i = 0; i < checks.length; i++) {
      const check = checks[i]
      if ((await check(args)).cancel) {
        return { cancel: true }
      }
    }
    return { cancel: false }
  }

  return (
    <WallabyRouterContext.Provider
      value={{
        registerUnloadHandler,
        registerBeforeUnloadHandler,
        checkUnload,
        beforeExit,
      }}
    >
      <Router hook={useWallabyLocationHook}>{children}</Router>
    </WallabyRouterContext.Provider>
  )
}
