import { useWindowEvent } from '@mantine/hooks'
import { Match, matchRoute, Path, useRouter } from 'wouter'

import { showConfirmModal } from '@components/Modals'
import {
  BeforeRouteChangeHandler,
  RouteChangeArgs,
  useBlockRouteChangeEffect,
  useUnloadRouteEffect,
} from '@routes'
import { useMst } from '@state'
import { ROUTE_PATTERNS } from '@util'

const editorPatterns = [
  ROUTE_PATTERNS.rundown,
  ROUTE_PATTERNS.scriptEditor,
  ROUTE_PATTERNS.splitEditor,
]

interface EditorParams {
  scriptId?: string
  rundownId?: string
}

interface MatcherFn {
  (pattern: Path, path: Path): Match
}

function matchEditorParams(matcher: MatcherFn, path: Path): EditorParams {
  for (const pattern of editorPatterns) {
    const [isMatch, params] = matcher(pattern, path)
    if (isMatch) return params
  }

  return {}
}

function useMatcher(): MatcherFn {
  const { parser } = useRouter()
  return (pattern, path) => matchRoute(parser, pattern, path)
}

// when navigating to a new route, remove script and rundown models
// if they're not on the next route
export const useUnloadEditors = () => {
  const matcher = useMatcher()
  const {
    currentScript,
    currentRundown,
    removeCurrentScript,
    removeCurrentRundown,
  } = useMst()

  useUnloadRouteEffect(({ to }) => {
    const params = matchEditorParams(matcher, to)

    if (currentRundown && String(currentRundown.id) !== params.rundownId) {
      removeCurrentRundown()
    }

    if (currentScript && currentScript.id !== params.scriptId) {
      removeCurrentScript()
    }
  })
}

// check for unsaved steps to block in-app navigation
export const useCheckUnsavedBeforeLeavingScript = () => {
  const { currentScript } = useMst()
  const matcher = useMatcher()

  const handler: BeforeRouteChangeHandler = async ({ to }: RouteChangeArgs) => {
    const shouldPromptToConfirm = (): boolean => {
      return (
        !!currentScript &&
        currentScript.id !== matchEditorParams(matcher, to).scriptId &&
        currentScript.syncStatus.sendStepsRequired
      )
    }

    if (!shouldPromptToConfirm()) {
      return {
        cancel: false,
      }
    }

    const userConfirmed = await showConfirmModal({
      children:
        "Scripto isn't finished syncing your most recent changes. Are you sure you want to leave this script?",
      title: 'Unsaved work',
      confirmLabel: 'Leave with unsaved work',
      cancelLabel: 'Wait until syncing completes',
      dangerous: true,
      size: 'lg',
    })

    return { cancel: !userConfirmed }
  }

  useBlockRouteChangeEffect(handler)
}

// If the user tries to leave the app with unsaved changes we block
// the window.beforeunload event but only if we haven't already put
// up a readonly banner.
export const useCheckUnsavedBeforeLeavingApp = () => {
  const { currentScript, socketManager } = useMst()

  const beforeUnloadHandler = (event: BeforeUnloadEvent) => {
    const shouldConfirmBeforeUnload =
      currentScript &&
      currentScript.syncStatus.sendStepsRequired &&
      !currentScript.syncStatus.isTooStaleForSafety &&
      socketManager.connected &&
      !currentScript.pmEditor.editabilityLost

    if (shouldConfirmBeforeUnload) {
      event.preventDefault()
    }
  }

  useWindowEvent('beforeunload', beforeUnloadHandler)
}
