import React from 'react'

import { useTimeout } from '@mantine/hooks'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import cn from 'classnames'

import { FaIcon, GreenCheckIcon } from '@components/FaIcon'
import { showError } from '@components/Modals'
import { Toolbar } from '@components/Toolbar'
import { useMst } from '@state'
import { ButtonMode, ILoadedScript } from '@state/types'

import styles from './CreateSnapshot.module.scss'

const cameraIcon = <FaIcon icon="fa-camera" />
const checkmarkIcon = <GreenCheckIcon />

export const CreateSnapshotButton = ({
  script,
  mode,
}: {
  script?: ILoadedScript
  mode?: ButtonMode
}) => {
  const { apiClient, doDebug, view } = useMst()
  const queryClient = useQueryClient()
  const [showSuccess, setShowSuccess] = React.useState(false)

  const label = showSuccess ? 'Saved' : 'Snapshot'

  // we set a timeout to trigger reverting the buttons default props
  const timeout = useTimeout(() => {
    setShowSuccess(false)
  }, 1000)

  const { mutate: createSnapshot } = useMutation({
    mutationFn: async () => {
      if (!script) throw new Error('No script loaded')
      await doDebug()
      return apiClient.createSnapshot({ scriptId: script.id, name: 'Snapshot' })
    },
    onSuccess() {
      setShowSuccess(true)
      timeout.start()
      view.snapshotLand.setHasNewer(true)
      queryClient.invalidateQueries({
        queryKey: ['snapshot-history', { scriptId: script?.id }],
      })
    },
    onError() {
      showError('An unexpected error was encountered saving a snapshot')
    },
  })

  return (
    <Toolbar.Button
      buttonClasses={cn({
        [styles.btn]: mode === 'normal',
      })}
      customIcon={showSuccess ? checkmarkIcon : cameraIcon}
      label={mode === 'normal' ? label : 'Create a snapshot'}
      tooltip="Create a snapshot"
      onClick={createSnapshot}
      mode={mode}
      focusEditor
    />
  )
}
