import React from 'react'

import { Button, Divider, Group, Radio, Stack, Text } from '@mantine/core'
import { useForm } from '@mantine/form'
import { observer } from 'mobx-react-lite'
import { Redirect, Route, Switch, useLocation } from 'wouter'

import { FormError, StartOver } from '@components/Onboarding/FormErrors'
import { OnboardingPage } from '@components/Onboarding/OnboardingPage'
import {
  BailoutLink,
  ThrottledBailoutLink,
} from '@components/Onboarding/OnboardingPage/BailoutLink'
import { PhoneNumberInput } from '@components/PhoneNumberInput'
import { Pinput } from '@components/Pinput'
import { useMst } from '@hooks'
import { RouteComponent } from '@routes/util'

interface FormValues {
  phoneNumber: string
  verificationCode: string
  deliveryMethod: 'sms' | 'whatsapp'
  methodId: string
}

export const SecondFactorRoute: RouteComponent = observer(() => {
  const { loading, authManager } = useMst()
  const { stytch, scrapi } = authManager
  const [, navigate] = useLocation()

  if (!stytch.verifiedPhone) {
    return <Redirect to="~/login" />
  }

  const form = useForm<FormValues>({
    initialValues: {
      phoneNumber: stytch.verifiedPhone.phoneNumber,
      verificationCode: '',
      deliveryMethod: 'sms',
      methodId: '',
    },
  })

  const phone = stytch.stytchUser?.phoneNumbers.find(
    (p) => p.phoneId === form.values.methodId,
  )

  async function handlePhoneSubmit(values: FormValues) {
    const result = await stytch.client.sendOtp(
      values.phoneNumber,
      values.deliveryMethod,
    )

    if (result.success) {
      form.setFieldValue('methodId', result.methodId)
      navigate('/phone/verify')
    } else {
      form.setFieldError('phoneNumber', result.message)
    }
  }

  async function handleSendCodeSubmit(values: FormValues) {
    const result = await stytch.client.sendOtp(
      values.phoneNumber,
      values.deliveryMethod,
    )

    if (result.success) {
      form.setFieldValue('methodId', result.methodId)
      navigate('/phone/verify')
    } else {
      form.setFieldError('phoneNumber', result.message)
    }
  }

  async function handleVerificationSubmit(values: FormValues) {
    if (!stytch.stytchUser) {
      return form.setFieldError(
        'verificationCode',
        <StartOver onClick={() => navigate('/login')} />,
      )
    }

    const result = await stytch.verifyOneTimePasscode(
      values.verificationCode,
      values.methodId,
    )

    if (!result.success) {
      return form.setFieldError('verificationCode', result.message)
    }

    const sessionCreation = await scrapi.auth.createSession({
      body: { type: 'stytchSession', email: stytch.stytchUser.email },
    })

    if (sessionCreation.status === 200) {
      authManager._onLoggedIn(sessionCreation.body.socketToken)
      window.history.replaceState({}, '', '/')
      window.location.reload()
    } else if (sessionCreation.status === 420) {
      form.setFieldError(
        'verificationCode',
        <FormError code={sessionCreation.body.code} />,
      )
    } else {
      form.setFieldError('verificationCode', <FormError />)
    }
  }

  // clear current otp and errors prior to resending
  async function handleResendCode(values: FormValues) {
    form.setFieldError('verificationCode', null)
    form.setFieldValue('verificationCode', '')
    handleSendCodeSubmit(values)
  }

  return (
    <Switch>
      <Route path="/phone/new">
        <OnboardingPage title="Keep your account secure" loading={loading}>
          <Stack gap="lg">
            <Stack gap="sm">
              <Text c="white" ta="center">
                Would you like to enable multi-factor authentication (MFA) for
                added protection?
              </Text>
              <Text c="white" ta="center">
                Enter your phone number to receive a one-time verification code
                when signing in.
              </Text>
            </Stack>
            <OnboardingPage.Form onSubmit={form.onSubmit(handlePhoneSubmit)}>
              <PhoneNumberInput
                autoFocus
                size="md"
                key={form.key('phoneNumber')}
                {...form.getInputProps('phoneNumber')}
              />
              <Group justify="center" gap="sm">
                <OnboardingPage.SubmitButton label="Text me a code" />
                <Button variant="outline" disabled>
                  No thanks
                </Button>
              </Group>
            </OnboardingPage.Form>
            <OnboardingPage.Links types={['support']} />
          </Stack>
        </OnboardingPage>
      </Route>

      <Route path="/phone/send">
        <OnboardingPage title="Phone authentication" loading={loading}>
          <Stack gap="lg">
            <Text c="white" ta="center">
              Enter your phone number to receive a one-time verification code
              when signing in.
            </Text>
            <OnboardingPage.Form onSubmit={form.onSubmit(handleSendCodeSubmit)}>
              <Text ta="center">Send a one-time verification code to</Text>
              <Text ta="center" fw="bold">
                {stytch.verifiedPhone.obfuscatedNumber}
              </Text>
              <Divider />
              <Radio.Group {...form.getInputProps('deliveryMethod')}>
                <Group justify="space-between">
                  <Radio value="sms" label="Send via SMS" size="xs" />
                  <Radio value="whatsapp" label="Send via WhatsApp" size="xs" />
                </Group>
              </Radio.Group>
              <Group justify="center" gap="sm" mt="xl">
                <OnboardingPage.SubmitButton label="Send code" />
              </Group>
            </OnboardingPage.Form>
            <OnboardingPage.Links types={['support']} />
          </Stack>
        </OnboardingPage>
      </Route>

      <Route path="/phone/verify">
        <OnboardingPage
          title="Keep your account secure"
          loading={loading || form.submitting}
        >
          <Stack gap="lg">
            <Stack gap="sm">
              <Text c="white" ta="center">
                Enter the 6-digit code we sent to
              </Text>
              <Text c="white" ta="center">
                {phone ? phone.obfuscatedNumber : '+x (xxx) xxx-xxxx'}
              </Text>
            </Stack>
            <OnboardingPage.Form
              onSubmit={form.onSubmit(handleVerificationSubmit)}
              errorMessage={form.errors.verificationCode}
            >
              <Pinput
                autoFocus
                disabled={form.submitting}
                onComplete={() => form.onSubmit(handleVerificationSubmit)()}
                {...form.getInputProps('verificationCode')}
              />

              <ThrottledBailoutLink
                message="Didn't receive a verification code?"
                linkText="Resend"
                successText="Sent"
                onClick={() => handleResendCode(form.values)}
              />
              {phone && !phone.verified && (
                <BailoutLink
                  message="Wrong phone number?"
                  linkText="Start over"
                  onClick={() => navigate('/phone/new')}
                />
              )}
            </OnboardingPage.Form>
            <OnboardingPage.Links types={['support']} />
          </Stack>
        </OnboardingPage>
      </Route>
      <Redirect to="/phone/new" />
    </Switch>
  )
})
