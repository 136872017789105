import React from 'react'

import {
  Anchor,
  Button,
  Checkbox,
  Group,
  Space,
  Stack,
  Table,
  TextInput,
} from '@mantine/core'
import { v4 as isUUID } from 'is-uuid'

import { schemas, ZInfer } from '@showrunner/scrapi'

import { showAsyncConfirmModal, showError } from '@components/Modals'
import { NavAnchor } from '@components/NavAnchor'
import { PaginatedPayloadView } from '@components/PaginatedPayloadView'
import { CopyableId } from '@components/Staff/CopyableId'
import { useMst } from '@state'

import { Breadcrumbs } from '../Breadcrumbs'

import { UserFilter, UserListing, UserListings } from './types'

const UsersResultsView = ({ results }: { results: UserListings }) => {
  const { apiClient } = useMst()
  const handleLogout = (user: UserListing) => {
    showAsyncConfirmModal({
      onConfirm: () => apiClient.revokeUserSessions(user.id),
      title: 'Log out User',
      children: `Are you sure you want to log ${user.email} out of all browsers?`,
      dangerous: true,
      errorMessage: 'An unexpected error occurred',
    })
  }

  return (
    <Table>
      <Table.Thead>
        <Table.Tr>
          <Table.Th>Staff</Table.Th>
          <Table.Th>User Name</Table.Th>
          <Table.Th>Email</Table.Th>
          <Table.Th>ID</Table.Th>
          <Table.Th>Workspace Count</Table.Th>
          <Table.Th></Table.Th>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {results.map((u) => {
          const { id, name, email, workspaceCount } = u
          return (
            <Table.Tr key={id}>
              <Table.Td>{u.staff ? '✅' : ''}</Table.Td>
              <Table.Td>
                <NavAnchor href={`/users/${id}`}>{name}</NavAnchor>
              </Table.Td>
              <Table.Td>{email}</Table.Td>
              <Table.Td>
                <CopyableId id={id} />
              </Table.Td>
              <Table.Td ta={'center'}>{workspaceCount}</Table.Td>
              <Table.Td>
                <Anchor onClick={() => handleLogout(u)}>Log out</Anchor>
              </Table.Td>
            </Table.Tr>
          )
        })}
      </Table.Tbody>
    </Table>
  )
}

type UserFilterProps = {
  filter: UserFilter
  onChange: (filter: UserFilter) => void
}

const UserFilterView = ({ onChange, filter }: UserFilterProps) => {
  const [formValues, setFormValues] = React.useState<UserFilter>({
    name: filter.name,
    email: filter.email,
    id: filter.id,
    staff: filter.staff,
  })

  const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      updateFilters()
      e.preventDefault()
    }
  }

  const updateFilters = () => {
    onChange({
      name: formValues.name,
      email: formValues.email,
      id: formValues.id,
      staff: formValues.staff,
    })
  }

  const resetFilters = () => {
    const cleared = {
      name: '',
      email: '',
      id: '',
      staff: undefined,
    }
    setFormValues(cleared)
    onChange(cleared)
  }

  return (
    <Stack>
      <Breadcrumbs crumbs={[{ text: 'Users' }]} />
      <Group gap={0} align="center">
        <TextInput
          placeholder="Jane User"
          value={formValues.name}
          onChange={(e) =>
            setFormValues({ ...formValues, name: e.target.value || undefined })
          }
          onKeyUp={(e) => handleKeyUp(e)}
        />
        <TextInput
          placeholder="jane@example.com"
          value={formValues.email}
          onChange={(e) =>
            setFormValues({ ...formValues, email: e.target.value || undefined })
          }
          onKeyUp={(e) => handleKeyUp(e)}
        />
        <TextInput
          placeholder="id"
          value={formValues.id}
          onChange={(e) =>
            setFormValues({ ...formValues, id: e.target.value || undefined })
          }
          onKeyUp={(e) => handleKeyUp(e)}
        />
        <Space w={10} />
        <Checkbox
          label="Staff only"
          checked={formValues.staff}
          onChange={(e) => {
            const staff = e.currentTarget.checked || undefined
            const newValues = { ...formValues, staff }
            setFormValues(newValues)
            onChange(newValues)
          }}
        />
      </Group>
      <Group justify="center">
        <Button onClick={updateFilters}>Search</Button>
        <Button variant="subtle" onClick={resetFilters}>
          Reset filters
        </Button>
      </Group>
      <Space h={10} />
    </Stack>
  )
}

export const Users = () => {
  const { scrapi } = useMst()

  const findUsersForStaff = async ({
    from,
    size,
    name,
    id,
    email,
    staff,
  }: ZInfer<typeof schemas.FindUsersRequest>) => {
    if (id && !isUUID(id)) {
      showError('an invalid id filter was ignored')
    }

    const response = await scrapi.staff.findUsers({
      body: {
        from,
        size,
        name,
        id: id && isUUID(id) ? id : undefined, // do not send an empty or invalid id
        email,
        staff,
      },
    })
    if (response.status !== 200) throw new Error('oops')
    return response.body
  }

  return (
    <PaginatedPayloadView
      getPage={findUsersForStaff}
      FilterView={UserFilterView}
      pageSize={50}
      ResultView={UsersResultsView}
      initialFilter={{}}
    />
  )
}
