import { useViewportSize } from '@mantine/hooks'
import { observer } from 'mobx-react-lite'

import { Loader } from '@components/Loader'
import { Toolbar } from '@components/Toolbar'
import { TabItem } from '@components/Toolbar/ToolbarTabs'
import { useMst } from '@hooks'

import { type SnapshotViewType } from '../types'

import { ExportMenu } from './ExportMenu'
import { SideBySideViewMenu } from './SideBySideViewMenu'
import { SlenePickerButton } from './SlenePickerButton'
import { SnapshotTimingPopover } from './SnapshotTimingPopover'
import { useSnapshotExportMenu } from './useSnapshotExportMenu'

type SnapshotTabItem = TabItem & {
  id: SnapshotViewType
}

// breakpoints for toolbar item sizes
const BUTTON_BREAKPOINT = 900
const TAB_BREAKPOINT = 650

const items: SnapshotTabItem[] = [
  { id: 'static', icon: 'fa-camera', label: 'Snapshot' },
  { id: 'diff', icon: 'fa-columns', label: 'Side-by-side' },
  { id: 'asterisk', icon: 'fa-asterisk', label: 'Asterisk' },
]

export const SnapshotLandToolbar = observer(function SnapshotLandToolbar({
  scriptId,
}: {
  scriptId: string
}) {
  const mst = useMst()
  const {
    currentView,
    monochrome,
    selectView,
    setMonochrome,
    setSideBySideContextLines,
    snap1,
    snap2,
  } = mst.view.snapshotLand

  const { width } = useViewportSize()
  const { canExport, isPrinting, handleCreatePdf, handleDownloadFdx } =
    useSnapshotExportMenu({ scriptId, snap1, snap2 })

  const tabMode = width < TAB_BREAKPOINT ? 'mini' : 'normal'
  const buttonMode = width < BUTTON_BREAKPOINT ? 'mini' : 'normal'

  const setSelectedTab = (newType: string | null) => {
    if (newType) {
      mst.view.snapshotLand.trackSnapshotEvent('SELECT_SNAPSHOT_TAB', {
        tab: newType,
      })
      selectView(newType)
    }
  }
  const preferredLinesOfContext =
    mst.user.prefs.snapshotLinesOfContext ?? 'some'

  return (
    <>
      <Loader visible={isPrinting} fullScreen />
      <Toolbar>
        <Toolbar.Section position="left">
          <Toolbar.Tabs
            mode={tabMode}
            selectedTab={currentView}
            onSelectTab={setSelectedTab}
            items={items}
          />
        </Toolbar.Section>
        <Toolbar.Section position="right">
          {currentView === 'diff' && (
            <>
              <SlenePickerButton mode={buttonMode} scriptId={scriptId} />
              <SideBySideViewMenu
                mode={buttonMode}
                monochrome={monochrome}
                setMonochrome={setMonochrome}
                linesOfContext={preferredLinesOfContext}
                setLinesOfContext={setSideBySideContextLines}
              />
              <Toolbar.Divider />
            </>
          )}
          {currentView === 'static' && (
            <SnapshotTimingPopover mode={buttonMode} scriptId={scriptId} />
          )}
          <Toolbar.CopyUrlButton mode={buttonMode} label="Copy URL" />
          <ExportMenu
            mode={buttonMode}
            disabled={!canExport}
            fdxEnabled={currentView === 'static'}
            onDownloadFdx={() => handleDownloadFdx()}
            onDownloadPdf={() => handleCreatePdf('download')}
            onPrint={() => handleCreatePdf('print')}
          />
        </Toolbar.Section>
      </Toolbar>
    </>
  )
})
