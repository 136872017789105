import React from 'react'

import {
  Button,
  CloseButton,
  createSafeContext,
  Group,
  Indicator,
  Popover,
  Space,
  Stack,
  Title,
} from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { observer } from 'mobx-react-lite'

import { useMst } from '@hooks'
import { AnnouncementType } from '@util/LocalPersistence'

import styles from './AnnouncementPopover.module.scss'

const [AnnouncementPopoverContextProvider, useAnnouncementContext] =
  createSafeContext<{
    enabled: boolean
    opened: boolean
    openAnnouncement(): void
    dismissAnnouncement(): void
  }>('No parent <AnnouncementPopover> component found in tree')

const AnnouncementPopoverInternal = (
  props: React.PropsWithChildren<{
    defaultToOpen?: boolean
    id?: AnnouncementType
  }>,
) => {
  const { user, view } = useMst()

  const enabled = props.id ? view.announcementEnabled(props.id) : true

  const [opened, { open, close }] = useDisclosure(props.defaultToOpen)

  const context = {
    opened,
    enabled,
    openAnnouncement: open,
    dismissAnnouncement() {
      close()
      if (props.id) user.acknowledgeAnnouncement(props.id)
    },
  }

  return (
    <AnnouncementPopoverContextProvider value={context}>
      <Popover
        trapFocus={true}
        opened={context.opened && context.enabled}
        withArrow
        arrowSize={15}
        width={350}
        shadow="xl"
      >
        {props.children}
      </Popover>
    </AnnouncementPopoverContextProvider>
  )
}

type TargetableElement = React.ReactElement<{
  variant?: 'light'
  onClick?: () => void
}>

const AnnouncementPopoverTarget = (props: { children: TargetableElement }) => {
  const { enabled, openAnnouncement } = useAnnouncementContext()

  if (!enabled) {
    return props.children
  }

  return (
    <Indicator offset={2} size={7}>
      <Popover.Target>
        {React.cloneElement(props.children, {
          variant: 'light',
          onClick: openAnnouncement,
        })}
      </Popover.Target>
    </Indicator>
  )
}

AnnouncementPopoverTarget.displayName = 'AnnouncementPopover.Target'
AnnouncementPopoverInternal.Target = AnnouncementPopoverTarget

const AnnouncementPopoverDropdown = (props: {
  title: React.ReactNode
  children: React.ReactNode
}) => {
  const { dismissAnnouncement } = useAnnouncementContext()

  return (
    <Popover.Dropdown
      px={0}
      pt={0}
      className={styles.announcementPopover_dropdown}
    >
      <Stack className={styles.announcementPopover_header}>
        <Group justify="flex-end" pt={5} px={5}>
          <CloseButton
            className={styles.announcementPopover_button}
            onClick={dismissAnnouncement}
            data-autofocus
          />
        </Group>
        <Title mt={-20} px={20} pb={20} order={3}>
          {props.title}
        </Title>
      </Stack>
      <Stack px={20} pt={5} pb={10}>
        <Space />
        {props.children}
        <Group justify="center">
          <Button
            size="xs"
            className={styles.announcementPopover_button}
            onClick={dismissAnnouncement}
          >
            Got it
          </Button>
        </Group>
      </Stack>
    </Popover.Dropdown>
  )
}

AnnouncementPopoverDropdown.displayName = 'AnnouncementPopover.Dropdown'
AnnouncementPopoverInternal.Dropdown = AnnouncementPopoverDropdown

export const AnnouncementPopover = observer(AnnouncementPopoverInternal)
