import React from 'react'

import { useInterval } from '@mantine/hooks'
import { useQuery, useQueryClient } from '@tanstack/react-query'

import { useMst } from '@state'

/*
  notes about staleTime/gcTime

  staleTime means: "How long is this query considered valid"
  gcTime means: "When no longer mounted, when should it be garbage collected"

  While in snapshot land for a script, we need to balance between reusing API
  responses and using too much memory.

  For the "current version" of the script, we should consider the value to
  be invalid pretty quickly, but it's still in discussion how this should work

  Snapshots on the other hand are immutable, so staleTime is Infinity, but we don't
  want to over-cache them.

*/
const minutesToMillis = (minutes: number) => minutes * 60 * 1000

// how often to poll for new snapshots when the query is mounted and
// we're in the foreground
const CHECK_FOR_LATEST_INTERVAL_MS = 60 * 1000

// Wrapper around mst's SnapshotLand model, but hooks into React to setup
// and teardown that state alongside the calling component's lifecycle
export function useSnapshotState(scriptId: string) {
  const { view } = useMst()
  const queryClient = useQueryClient()
  const queryKey = view.snapshotLand.scriptQueryKey(scriptId)

  const interval = useInterval(() => {
    queryClient.invalidateQueries({ queryKey })
    view.snapshotLand.checkForNewer(scriptId)
  }, CHECK_FOR_LATEST_INTERVAL_MS)

  React.useEffect(() => {
    interval.start()
    return interval.stop
  }, [interval])

  React.useEffect(() => {
    view.snapshotLand.initializeForScript(scriptId)
    return view.snapshotLand.tearDownSnapshotLand
  }, [scriptId, view.snapshotLand])

  return view.snapshotLand
}

export const useSnapshotData = ({
  snapshotId,
  scriptId,
}: {
  snapshotId: string
  scriptId: string
}) => {
  const { apiClient, doDebug, view } = useMst()

  return useQuery({
    queryFn: async () => {
      await doDebug()
      return apiClient.getSnapshot({ scriptId, snapshotId })
    },
    queryKey: view.snapshotLand.snapshotQueryKey({ snapshotId, scriptId }),
    // if we have a snapshot in the cache, it is not stale
    staleTime: Infinity,
    // keep for 5 minutes if a snapshot is unmounted
    gcTime: minutesToMillis(5),
  })
}

export const useMaybeSnapshot = ({
  snapshotId,
  scriptId,
}: {
  snapshotId?: string
  scriptId: string
}) => {
  const { apiClient, doDebug, view } = useMst()
  return useQuery({
    queryFn: async () => {
      await doDebug()
      if (snapshotId === 'current') {
        // it would be nicer to lean on useScriptData
        const dupe = await apiClient.getScript(scriptId)
        const { scriptDoc, scriptFetchedAt } = view.snapshotLand

        const doc = scriptDoc ?? dupe.doc
        const createdAt =
          scriptFetchedAt?.toISOString() ?? new Date().toISOString()

        return {
          ...dupe,
          doc,
          name: 'Current script',
          createdAt,
          scriptId,
          createdBy: '',
          creatorName: '',
          autoSave: null,
        }
      }

      if (snapshotId) {
        return apiClient.getSnapshot({ scriptId, snapshotId })
      }
      return null
    },
    queryKey: view.snapshotLand.snapshotQueryKey({
      snapshotId: snapshotId ?? 'none',
      scriptId,
    }),
    // if we have a snapshot in the cache, it is not stale
    // when we're loading the current script, we should not cache
    staleTime: () => (snapshotId === 'current' ? 0 : Infinity),
    // keep for 5 minutes if a snapshot is unmounted
    gcTime: minutesToMillis(5),
  })
}

export const useSelectedSnapshots = (scriptId: string) => {
  const { snap1, snap2 } = useMst().view.snapshotLand
  const topQuery = useMaybeSnapshot({ scriptId, snapshotId: snap1 })
  const bottomQuery = useMaybeSnapshot({ scriptId, snapshotId: snap2 })

  return {
    topQuery,
    bottomQuery,
  }
}

export const useScriptData = (scriptId: string) => {
  const mst = useMst()
  const queryKey = mst.view.snapshotLand.scriptQueryKey(scriptId)

  return useQuery({
    queryFn: async () => {
      await mst.doDebug()
      const script = await mst.apiClient.getScript(scriptId)
      const fetchedAt = new Date()

      const { scriptVersion } = mst.view.snapshotLand

      if (scriptVersion) {
        if (scriptVersion < script.version) {
          mst.view.snapshotLand.setHasNewer(true)
        }
      } else {
        mst.view.snapshotLand.setScriptVersion(script.version)
        mst.view.snapshotLand.setScriptDoc(script.doc)
        mst.view.snapshotLand.setScriptFetchedAt(fetchedAt)
      }

      return {
        script,
        fetchedAt,
      }
    },

    queryKey,
    // TODO: revisit these
    staleTime: Infinity,
    gcTime: 0,
  })
}
