import React from 'react'

import { InputBase, InputBaseProps } from '@mantine/core'
import { IMask, IMaskInput as MaskInput } from 'react-imask'

import { FaIcon } from '@components/FaIcon'

export type PhoneNumberInputProps = InputBaseProps &
  Omit<
    React.ComponentPropsWithoutRef<typeof MaskInput>,
    keyof InputBaseProps | 'onChange'
  > & { onChange?: (e164PhoneNumber: string) => void }

const icon = <FaIcon icon="fa-mobile-screen" size="xl" />

const mask = new IMask.MaskedDynamic({
  mask: [
    {
      mask: '+0 (000) 000-0000',
      // @ts-expect-error - IMask.DynamicMaskType is not generic
      countryCode: '+1',
      country: 'US',
    },
    {
      mask: '+00 0000 000000',
      // @ts-expect-error - IMask.DynamicMaskType is not generic
      countryCode: '+44',
      country: 'UK',
    },
    {
      mask: '+000000000000000',
      // @ts-expect-error - IMask.DynamicMaskType is not generic
      countryCode: '',
      country: 'unknown',
    },
  ],
  dispatch: (_appended, dynamicMask) => {
    return dynamicMask.compiledMasks.find((mask) =>
      // @ts-expect-error - IMask.DynamicMaskType is not generic
      dynamicMask.value.startsWith(mask.countryCode),
    )
  },
})

export const PhoneNumberInput = (props: PhoneNumberInputProps) => {
  const { onChange, ...otherProps } = props

  const handleAccept = React.useCallback(
    (unmaskedValue: string) => {
      const e164PhoneNumber = `+${unmaskedValue}`
      if (e164PhoneNumber !== props.value) {
        onChange?.(e164PhoneNumber)
      }
    },
    [onChange, props.value],
  )

  return (
    <InputBase
      type="tel"
      component={MaskInput}
      label="Phone number"
      placeholder="+1 (111) 111-1111"
      leftSection={icon}
      mask={mask}
      unmask={true}
      onAccept={handleAccept}
      {...otherProps}
    />
  )
}
