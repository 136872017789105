import { observer } from 'mobx-react-lite'

import { AlignmentTypeMap } from '@showrunner/codex'

import { Toolbar } from '@components/Toolbar'
import { useMst } from '@state'
import { capitalize, setAlignment } from '@util'

export const AlignmentButtons = observer(function AlignmentButtons() {
  const { currentScript } = useMst()
  if (!currentScript?.observableEditor) {
    return null
  }
  const { editorView, editorState, disableAlignment, selectionAlignment } =
    currentScript.observableEditor

  return (
    <>
      {[
        AlignmentTypeMap.ALIGN_LEFT,
        AlignmentTypeMap.ALIGN_CENTER,
        AlignmentTypeMap.ALIGN_RIGHT,
      ].map((opt) => {
        const button = (
          <Toolbar.Button
            key={opt}
            icon={`fa-align-${opt}`}
            tooltip={capitalize(opt) + ' align'}
            onClick={() => {
              setAlignment(opt)(editorState, editorView.dispatch)
              currentScript.trackEvent('PARAGRAPH_ALIGNMENT_CHANGED')
            }}
            disabled={disableAlignment}
            active={selectionAlignment === opt}
            focusEditor
          />
        )

        return button
      })}
    </>
  )
})
