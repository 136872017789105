import React from 'react'

import NiceModal from '@ebay/nice-modal-react'
import {
  Button,
  Fieldset,
  Group,
  LoadingOverlay,
  Modal,
  PinInput,
  Radio,
  Space,
  Stack,
  Text,
} from '@mantine/core'
import { useForm } from '@mantine/form'

import { FaIcon } from '@components/FaIcon'
import { useModalControls } from '@components/Modals'
import { IncorrectOtp } from '@components/Onboarding/FormErrors'
import { ThrottledBailoutLink } from '@components/Onboarding/OnboardingPage/BailoutLink'
import { PhoneNumberInput } from '@components/PhoneNumberInput'
import { Toast } from '@components/Toast'
import { useEmail } from '@hooks/useEmail'
import { useMst } from '@state'
import { formUtil } from '@util'

type FormValues = {
  phoneNumber: string
  otp: string
}

type OtpDeliveryOption = 'sms' | 'whatsapp'

export const EnablePhoneAuth = () => {
  const controls = useModalControls()
  const mst = useMst()
  const mfaEnabledEmail = useEmail('mfa-added')
  const [oneTimePasscodeId, setOneTimePasscodeId] = React.useState('')
  const [sendVia, setSendVia] = React.useState<OtpDeliveryOption>('sms')

  const phoneSubmitted = !!oneTimePasscodeId

  const form = useForm<FormValues>({
    initialValues: {
      phoneNumber: '',
      otp: '',
    },
    validate: {
      phoneNumber: (value) => {
        return formUtil.validateNotEmpty(value)
      },
      otp: (value) => {
        if (oneTimePasscodeId) {
          return formUtil.validateOtpCode(value)
        }
      },
    },
  })

  const resendOtp = async () => {
    // clear the visible field, but not the phone number entered previously
    form.setFieldValue('otp', '')
    controls.setErrorMessage(null)
    form.setSubmitting(true)
    await requestOtp()
    form.setSubmitting(false)
  }

  const requestOtp = async () => {
    const result = await mst.authManager.stytch.client.sendOtp(
      form.values.phoneNumber,
      sendVia,
    )

    if (result.success) {
      setOneTimePasscodeId(result.methodId)
    } else {
      controls.setErrorMessage(result.message)
    }
  }

  const reset = () => {
    form.reset()
    controls.setErrorMessage(null)
    setOneTimePasscodeId('')
  }

  const handleClose = () => {
    reset()
    controls.onClose()
  }

  const handleSubmit = form.onSubmit(async ({ otp }) => {
    if (!phoneSubmitted) {
      // clear previous errors
      controls.setErrorMessage(null)
      await requestOtp()
    } else {
      const result = await mst.authManager.stytch.verifyOneTimePasscode(
        otp,
        oneTimePasscodeId,
      )

      if (result.success) {
        mst.trackEvent('USER_ENABLED_PHONE_MFA', { userId: mst.user.id })
        mfaEnabledEmail.send()
        handleClose()
      } else if (result.code === 'OTP_EXPIRED_OR_USED') {
        controls.setErrorMessage(<IncorrectOtp onClick={resendOtp} />)
        form.setFieldValue('otp', '')
      } else {
        controls.setErrorMessage(result.message)
      }
    }
  })

  return (
    <Modal
      title="Enable SMS authentication"
      opened={controls.opened}
      onClose={controls.onClose}
      size="sm"
    >
      <LoadingOverlay visible={form.submitting} />
      <form onSubmit={handleSubmit}>
        <Fieldset variant="unstyled" p={0} disabled={form.submitting}>
          <Stack>
            {controls.errorMessage && (
              <Toast
                type="error"
                dismissable={false}
                message={controls.errorMessage}
              />
            )}
            {phoneSubmitted ? (
              <>
                <Text>
                  Please provide the 6-digit verification code that was just
                  sent to&nbsp;
                  <Text span fw="bold">
                    {form.getInputProps('phoneNumber').value}
                  </Text>
                  .
                </Text>
                <Group justify="center">
                  <Text fw="bold">Verification Code</Text>
                  <PinInput
                    oneTimeCode
                    aria-label="One time code"
                    length={6}
                    onComplete={() => handleSubmit()}
                    {...form.getInputProps('otp')}
                  />
                </Group>
              </>
            ) : (
              <>
                <Text>
                  Enter your phone number to receive a one-time verification
                  code when signing in.
                </Text>
                <PhoneNumberInput
                  size="md"
                  disabled={phoneSubmitted}
                  {...form.getInputProps('phoneNumber')}
                />
                <Radio.Group
                  value={sendVia}
                  onChange={(val) => setSendVia(val as OtpDeliveryOption)}
                >
                  <Group>
                    <Radio label="SMS / text message" value="sms" />
                    <Radio
                      label={
                        <Group gap={3}>
                          <FaIcon icon="fa-brands fa-whatsapp" />
                          <Text>WhatsApp</Text>
                        </Group>
                      }
                      value="whatsapp"
                    />
                  </Group>
                </Radio.Group>
              </>
            )}
          </Stack>
          <Space h={40} />
          <Stack>
            <Group gap="xs" justify="center">
              <Button variant="filled" type="submit" disabled={form.submitting}>
                {phoneSubmitted ? 'Submit' : 'Next'}
              </Button>
              <Button variant="outline" onClick={handleClose}>
                Cancel
              </Button>
            </Group>
          </Stack>
        </Fieldset>
        {phoneSubmitted && (
          <>
            <Space h={20} />
            <ThrottledBailoutLink
              message="Didn't receive a code?"
              linkText="Resend"
              successText="Sent"
              onClick={resendOtp}
            />
          </>
        )}
      </form>
    </Modal>
  )
}

export const EnablePhoneAuthModal = NiceModal.create(EnablePhoneAuth)
export const showEnablePhoneAuthModal = () =>
  NiceModal.show(EnablePhoneAuthModal)
