import { Text } from '@mantine/core'

import {
  SnapshotCard,
  SnapshotFilter,
  SnapshotListEntries,
  snapshotsPath,
  useSnapshotState,
} from '@components/SnapshotLand'

import { Panel } from './Panel'

interface Props {
  scriptId: string
  onClose(): void
  isOpen: boolean
}

export function SnapshotsPanel(props: Props) {
  if (!props.isOpen) return null

  return <SnapshotSidebar scriptId={props.scriptId} onClose={props.onClose} />
}

function SnapshotSidebar(props: { scriptId: string; onClose(): void }) {
  useSnapshotState(props.scriptId)

  const header = (
    <>
      <Text fw="bold" mr="auto">
        Snapshots
      </Text>
      <SnapshotFilter scriptId={props.scriptId} />
    </>
  )

  return (
    <Panel header={header} onClose={props.onClose}>
      <SnapshotListEntries>
        {(snapshot) => (
          <SnapshotCard
            href={snapshotsPath(props.scriptId, { snap1: snapshot.id })}
            data={snapshot}
          />
        )}
      </SnapshotListEntries>
    </Panel>
  )
}
