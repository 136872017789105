import { EditorState, PluginKey } from 'prosemirror-state'
import { Decoration, DecorationSet } from 'prosemirror-view'

import { blockInfoPluginKey } from '@choo-app/lib/editor/plugins/block-info'
import { BlockInfo } from '@util'

export type Inventory = BlockInfo[]

export const characterContdsPluginKey = new PluginKey<Inventory>(
  'characterContds',
)

export const getContdInventory = (state: EditorState): Inventory =>
  blockInfoPluginKey.getState(state)?.filter((b) => b.isContd) ?? []

const gutterDecorations = ({
  displayText,
  inventory,
}: {
  displayText: string
  inventory: Inventory
}): Decoration[] =>
  inventory.map(({ id, pos, nodeSize }) =>
    Decoration.widget(
      pos + nodeSize - 1,
      () => {
        const elt = document.createElement('span')
        elt.classList.add('o-character-contd')
        elt.innerHTML = displayText
        return elt
      },
      {
        key: id + '-contd',
        side: 1,
      },
    ),
  )

export const getDecorations = ({
  displayText,
  state,
}: {
  displayText: string
  state: EditorState
}) => {
  const inventory = characterContdsPluginKey.getState(state)
  return inventory
    ? DecorationSet.create(
        state.doc,
        gutterDecorations({ displayText, inventory }),
      )
    : DecorationSet.empty
}
