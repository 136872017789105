import React from 'react'

import { Button, Group, Text } from '@mantine/core'
import { useMutation } from '@tanstack/react-query'
import { useLocation } from 'wouter'

import { OnboardingPage } from '@components/Onboarding/OnboardingPage'
import { useMst } from '@state'
import { reloadWindow } from '@util'

/**
 * A page in the app that allows the current user to log out
 */
export const LogoutRoute = () => {
  const { user } = useMst()
  const mutation = useLogoutMutation()

  return (
    <OnboardingPage title="Sign out?" loading={mutation.isPending}>
      <OnboardingPage.Form
        onSubmit={(event) => {
          event?.preventDefault()
          mutation.mutate()
        }}
        errorMessage={mutation.error?.message}
      >
        <Text ta="center">
          You&apos;re signed in as:&nbsp;
          <Text span fw="bold">
            {user.email}
          </Text>
        </Text>
        <Group justify="center" gap="sm">
          <Button variant="outline" onClick={() => window.history.back()}>
            Go back
          </Button>
          <OnboardingPage.SubmitButton label="Sign out" />
        </Group>
      </OnboardingPage.Form>
    </OnboardingPage>
  )
}

/**
 * When submitted, perform the logout mutation and fall back to the manual
 * /logout page if there's an error
 */
export const LogoutForm: React.FC<React.PropsWithChildren> = (props) => {
  const mutation = useLogoutMutation()
  const [, navigate] = useLocation()

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault()
        mutation.mutateAsync().catch(() => navigate('/logout'))
      }}
    >
      {props.children}
    </form>
  )
}

function useLogoutMutation() {
  const mst = useMst()

  return useMutation({
    async mutationFn() {
      const response = await mst.scrapi.auth.logout({ body: {} })

      if (response.status !== 200) {
        throw new Error('Unknown failure trying to log out')
      }

      return response.body
    },
    onSuccess() {
      mst.authManager.cleanupSession()
      reloadWindow('/login')
    },
  })
}
