import { Node as PmNode } from 'prosemirror-model'
import { EditorState } from 'prosemirror-state'
import { EditorView } from 'prosemirror-view'
import { renderToString } from 'react-dom/server'

import { util } from '@showrunner/codex'
import { schema } from '@showrunner/prose-schemas'

import { blockInfoFactory } from '@choo-app/lib/editor/plugins/block-info'
import { characterContdsPlugin } from '@choo-app/lib/editor/plugins/character'
import { TwoColumnScript } from '@components/TwoColumnScript'
import { buildFormatStyleString } from '@layouts/Script/buildFormatStyles'
import {
  DEFAULT_CONTD_DISPLAY_TEXT,
  formatSeconds,
  getPrintTimestamp,
} from '@util'
import {
  appendFontFallback,
  BlockFormats,
  FontCode,
  FontCodeMap,
  ScriptFormatConfiguration,
} from '@util/formats'
import { getPageLayout, PageLayout } from '@util/PageLayout'
import { createPaginationBreakdown } from '@util/pagination'
import * as cssStrings from '@util/printing/cssStrings'
import { createProsemirrorDoc, PaginationType } from '@util/prosemirrorHelpers'
import { RundownRowData, ScriptJson } from '@util/ScriptoApiClient/types'
import {
  PageLayout as PageDimensions,
  PageMarginSizes,
  ScriptPrintPreferences,
} from '@util/zodSchemas/printPreferences'

import { buildHtmlForPrince } from './buildHtmlForPrince'
import { buildPageCssVars } from './buildPageCssVars'
import * as constants from './constants'
import { ScriptHeaderAndFooter } from './HeaderAndFooter'
import { IPrincePrintStrategy } from './types'

// when we print a whole script, we create a snapshot
// and pull the doc and formatDefinition from the immutable artifact
// when we print a section, we pass the same props directly
type ScriptPrintStrategyParams = {
  doc: ScriptJson
  formatDefinition: ScriptFormatConfiguration
  prefs: ScriptPrintPreferences
  timestamp: string
  title: string
  readRate?: number
  duration?: string
  rundownRowData?: RundownRowData
}

type AsteriskPrintStrategyParmas = {
  html: string
} & ScriptPrintStrategyParams

const generateOneColumnStyles = ({
  monochrome,
  blockFormats,
  pageLayout,
  fontCode = 'courier-prime',
}: {
  monochrome: boolean
  blockFormats: BlockFormats
  pageLayout: PageLayout
  fontCode: FontCode | undefined
}) => `
${buildFormatStyleString({ blockFormats, monochrome, pageLayout })}
${cssStrings.editorCSS}
${cssStrings.oneColumnCSS}
:root {
  --script-font-family: ${appendFontFallback(FontCodeMap[fontCode])};
}
`

// This abstract class is the common base for printing 1 or 2 column scripts and should contain
// any state or logic that is common across both
abstract class BaseScriptPrintStrategy implements IPrincePrintStrategy {
  protected prefs: ScriptPrintPreferences
  protected title: string
  protected timestamp: string
  protected duration: string | undefined
  protected rundownRowData: RundownRowData | undefined
  protected doc: PmNode
  protected blockFormats: BlockFormats
  protected paginationType: PaginationType | undefined
  protected fontCode: FontCode | undefined
  protected pageLayout: PageLayout
  protected enableCharContds: boolean
  protected contdDisplayText: string

  constructor({
    doc,
    formatDefinition,
    prefs,
    title,
    timestamp,
    duration,
    rundownRowData,
  }: ScriptPrintStrategyParams) {
    this.doc = createProsemirrorDoc(doc)
    this.blockFormats = formatDefinition.blocks
    this.paginationType = formatDefinition.paginationType
    this.prefs = prefs
    this.timestamp = timestamp
    this.title = title
    this.duration = duration
    this.rundownRowData = rundownRowData
    this.fontCode = formatDefinition.fontCode
    this.pageLayout = getPageLayout(formatDefinition.pageConfig)
    this.enableCharContds = !!formatDefinition.moresContds?.enableCharContds
    this.contdDisplayText =
      formatDefinition.moresContds?.contdDisplayText ??
      DEFAULT_CONTD_DISPLAY_TEXT
  }

  // The One and Two-column strategies are responsible for implementing these
  // measurements for the margins
  abstract getMargins(): PageMarginSizes
  // any styles specific to the print format
  abstract generateFormatSpecificStyles(): string
  // the HTML that goes into the body
  abstract generateBody(): string

  generateHeadElements(): string {
    return constants.FONTS
  }

  // this combines styles common to all script print html with
  // the format-specific ones provided by the subclass
  generateStyles() {
    return `
      ${
        this.prefs.headers.showOnFirstPage
          ? ''
          : constants.HIDE_HEADERS_ON_FIRST_PAGE
      }
      ${
        this.prefs.footers.showOnFirstPage
          ? ''
          : constants.HIDE_FOOTERS_ON_FIRST_PAGE
      }
      ${buildPageCssVars({
        layout: this.generatePageLayout(),
        title: this.title,
        timestamp: this.timestamp,
        monochrome: this.prefs.monochrome,
        duration: this.duration,
        rundownRowData: this.rundownRowData,
      })}
      ${cssStrings.headerFooterCSS}
      ${this.generateFormatSpecificStyles()}
    `
  }

  generatePageLayout(): PageDimensions {
    const { pageHeightPx, pageWidthPx } = this.pageLayout
    const orientation = pageHeightPx > pageWidthPx ? 'portrait' : 'landscape'

    return {
      size: { height: `${pageHeightPx}px`, width: `${pageWidthPx}px` },
      margins: this.getMargins(),
      orientation,
    }
  }

  generateHeaderAndFooter() {
    return renderToString(<ScriptHeaderAndFooter prefs={this.prefs} />)
  }
}

class OneColumnPrintStrategy extends BaseScriptPrintStrategy {
  constructor(params: ScriptPrintStrategyParams) {
    super(params)
  }

  getMargins(): PageMarginSizes {
    const { pageMarginsPx } = this.pageLayout
    // we need to cheat the bottom margin a little, moving it
    // down by 48px then re-adjusting in OneColumnScript.scss
    // to move it back up... this allows our imprecise pagination
    // to overflow a bit.
    const adjustedBottom = pageMarginsPx.bottom - 48
    return {
      left: `${pageMarginsPx.left}px`,
      right: `${pageMarginsPx.right}px`,
      top: `${pageMarginsPx.top}px`,
      bottom: `${adjustedBottom}px`,
    }
  }

  generateFormatSpecificStyles() {
    return generateOneColumnStyles({
      monochrome: this.prefs.monochrome,
      blockFormats: this.blockFormats,
      fontCode: this.fontCode,
      pageLayout: this.pageLayout,
    })
  }

  generateBody() {
    const plugins = this.enableCharContds
      ? [
          blockInfoFactory(),
          characterContdsPlugin({
            script: {
              scriptFormat: {
                definition: {
                  moresContds: { contdDisplayText: this.contdDisplayText },
                },
              },
            },
          }),
        ]
      : []

    const ev = new EditorView(null, {
      state: EditorState.create({ doc: this.doc, plugins, schema }),
      editable: () => false,
    })

    const docFragment = ev.dom
    const serializer = new XMLSerializer()
    const isInline = this.paginationType === 'inline'

    const paginationClass = isInline ? 'is-inline' : 'is-structural'

    if (isInline) {
      const { pageBreaks } = createPaginationBreakdown({
        doc: this.doc,
        blockFormats: this.blockFormats,
        pageLayout: this.pageLayout,
      })
      pageBreaks.forEach(({ blockId, pageNumber }) => {
        const elt = docFragment.querySelector(`[id='${blockId}']`)
        elt?.classList.add('start-of-page')
        // not used yet because we're just doing 1,2,3... but
        // once we have page locking, this can be wired up into
        // the print css
        elt?.setAttribute('data-page-number', pageNumber)
      })
    }

    const payload = `
      <div contenteditable="false" translate="no" class="ProseMirror is-static ${paginationClass}">
        ${serializer.serializeToString(docFragment)}
      </div>
    `
    // garbage collection
    ev.destroy()

    return payload
  }
}

class TwoColumnPrintStrategy extends BaseScriptPrintStrategy {
  constructor(params: ScriptPrintStrategyParams) {
    super(params)
  }

  getMargins = () => constants.TWO_COLUMN_MARGIN_SIZE

  generateFormatSpecificStyles() {
    return cssStrings.twoColumnCSS
  }

  generateBody() {
    const scriptJson = this.doc.toJSON() as ScriptJson
    return renderToString(
      <TwoColumnScript
        prefs={this.prefs}
        scriptJson={scriptJson}
        blockFormats={this.blockFormats}
      />,
    )
  }
}

class OneColumnAsteriskPrintStrategy extends OneColumnPrintStrategy {
  protected html: string

  constructor(params: AsteriskPrintStrategyParmas) {
    super(params)
    this.html = params.html
  }

  generateBody() {
    return this.html
  }
}

// this is what is passed in to the helper functions
type PrintScriptParams = {
  doc: ScriptJson
  formatDefinition: ScriptFormatConfiguration
  prefs: ScriptPrintPreferences
  title: string
  readRate?: number
  rundownRowData?: RundownRowData
}

// we can use the PrintScriptParams to generate duration & timestamp
const enrichWithComputedValues = <T extends PrintScriptParams>(
  params: T,
): T & { timestamp: string; duration?: string } => {
  const { doc, readRate } = params
  const isScreenplay = doc.attrs.docType === 'screenplay'
  const timestamp = getPrintTimestamp()
  const duration =
    !isScreenplay && readRate
      ? formatSeconds(
          new util.ScriptBreakdown(createProsemirrorDoc(doc), readRate).timing
            .totalSeconds,
        )
      : undefined
  return {
    ...params,
    timestamp,
    duration,
  }
}

export const buildPrintableScriptHtml = (params: PrintScriptParams): string => {
  const { doc, prefs } = params
  const isScreenplay = doc.attrs.docType === 'screenplay'
  const shouldPrintTwoColumn = prefs.columns && !isScreenplay
  const strategyParams = enrichWithComputedValues(params)
  const strategy: IPrincePrintStrategy = shouldPrintTwoColumn
    ? new TwoColumnPrintStrategy(strategyParams)
    : new OneColumnPrintStrategy(strategyParams)

  return buildHtmlForPrince(strategy)
}

export const buildPrintableRevisionHtml = (
  params: PrintScriptParams & { html: string },
): string => {
  const strategy = new OneColumnAsteriskPrintStrategy(
    enrichWithComputedValues(params),
  )
  return buildHtmlForPrince(strategy)
}
