import React from 'react'

import { useDebouncedElementSize } from '@hooks'
import { useMst } from '@state'
import {
  appendFontFallback,
  BlockFormats,
  FontCode,
  FontCodeMap,
} from '@util/formats'
import { PageLayout } from '@util/PageLayout'
import { PaginationType } from '@util/prosemirrorHelpers/types'

import { buildFormatStyleProp } from './buildFormatStyles'
import { PagelessWrapper } from './PagelessWrapper'
import { PrintPreviewWrapper } from './PrintPreviewWrapper'

import styles from './ScriptWrapper.module.scss'

export interface ScriptWrapperProps {
  pageCount: number
  noWrap?: boolean
  pageless?: boolean
  children: React.ReactNode
  zoomLevel: number
  blockFormats?: BlockFormats
  paginationType?: PaginationType
  pageLayout: PageLayout
  showMarginRuler?: boolean
  fontCode: FontCode
}

export const ScriptWrapper = ({
  children,
  pageless,
  noWrap,
  zoomLevel,
  pageCount,
  blockFormats,
  paginationType,
  pageLayout,
  showMarginRuler = false,
  fontCode,
}: ScriptWrapperProps) => {
  const { ref, height, width } = useDebouncedElementSize()
  const { view } = useMst()

  // debug behavior: use a standard stylesheet for all scripts
  // and regenerate a set of css vars whenever the format definition
  // changes
  const formatStyleVars = React.useMemo<React.CSSProperties>(
    () =>
      blockFormats ? buildFormatStyleProp({ blockFormats, pageLayout }) : {},
    [blockFormats, pageLayout],
  )

  const pageLayoutVars = React.useMemo(
    () => pageLayout.getCssVars(),
    [pageLayout],
  )

  React.useEffect(() => {
    view.dimensions.scriptScroller.update({
      height,
      width,
    })
  }, [height, view.dimensions.scriptScroller, width])

  const inlineStyles: React.CSSProperties = {
    ...formatStyleVars,
    ...pageLayoutVars,
    '--total-page-count': `' of ${pageCount}'`,
    '--zoom-level': zoomLevel,
    '--prose-editor-height': `${height}px`,
    '--prose-editor-width': `${width}px`,
    '--script-font-family': appendFontFallback(FontCodeMap[fontCode]),
  }
  if (pageless) {
    inlineStyles['--pageless-block-width'] = 'auto'
  }

  return (
    <div ref={ref} className={styles.scriptWrapper} style={inlineStyles}>
      {paginationType === 'none' || pageless ? (
        <PagelessWrapper
          isStructural={paginationType === 'structural'}
          noWrap={noWrap}
        >
          {children}
        </PagelessWrapper>
      ) : (
        <PrintPreviewWrapper
          isStructural={paginationType === 'structural'}
          zoomLevel={zoomLevel}
          showMarginRuler={showMarginRuler}
        >
          {children}
        </PrintPreviewWrapper>
      )}
    </div>
  )
}
