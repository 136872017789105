import React from 'react'

import { Text } from '@mantine/core'
import { useQuery } from '@tanstack/react-query'
import { observer } from 'mobx-react-lite'

import { FaIcon } from '@components/FaIcon'
import { AnnouncementPopover } from '@components/ProductTour/AnnouncementPopover'
import { Toolbar } from '@components/Toolbar'
import { useMst } from '@hooks'
import { ButtonMode, ILoadedScript } from '@state/types'
import { pluralize } from '@util'

interface Props {
  script?: ILoadedScript
  toggleSnapshotsPanel: () => void
  mode?: ButtonMode
}

export const OpenSnapshotSidebar = observer<Props>((props) => {
  const { apiClient } = useMst()
  let label = 'Snapshots'

  const { data: count } = useQuery({
    queryKey: ['snapshot-history', { scriptId: props.script?.id }],
    queryFn: async () => {
      if (!props.script) return 0

      const { total } = await apiClient.fetchSnapshotHistory({
        scriptId: props.script.id,
        from: 0,
        size: 1,
        filter: 'all',
      })

      return total
    },
    initialData: 0,
    enabled: Boolean(props.script?.id),
  })

  if (count > 0) {
    label = `${count} ${pluralize(count, 'Snapshot')}`
  }

  return (
    <AnnouncementPopover id="snapshot-sidebar" defaultToOpen>
      <AnnouncementPopover.Target>
        <Toolbar.Button
          customIcon={<FaIcon icon="fa-clock-rotate-left" />}
          label={label}
          mode={props.mode}
          onClick={props.toggleSnapshotsPanel}
        />
      </AnnouncementPopover.Target>
      <AnnouncementPopover.Dropdown title="New snapshot list 🎉">
        <Text>
          You can now view a list of your script&apos;s snapshots without
          needing to exit the editor!
        </Text>
        <Text>
          Just click{' '}
          <Text span fw="bold">
            <FaIcon span icon="fa-clock-rotate-left" /> Snapshots
          </Text>{' '}
          to open the panel.
        </Text>
      </AnnouncementPopover.Dropdown>
    </AnnouncementPopover>
  )
})
