import React, { ReactElement } from 'react'

import {
  Match,
  matchRoute,
  MatchWithParams,
  RouteProps,
  useLocation,
  useRouter,
} from 'wouter'

import { LayoutComponent } from './types'

// Wouter doesn't expose the full type of the Route component's props, since it
// tries to hide an implementation detail of the Switch component. A Switch will
// crawl its children looking for a matching path, and if so, force the Route to
// render by setting this match prop. We do the same trick in this component to
// wrap a component in a layout, so we extend the type.
interface FullRouteProps extends RouteProps {
  match?: MatchWithParams
}

type RouteableElement = ReactElement<FullRouteProps>

/**
 * A component that wraps chlid routes inside a layout component
 */
export function LayoutRoute(props: {
  children: RouteableElement | RouteableElement[]
  component: LayoutComponent
}) {
  const LayoutComponent = props.component
  const { parser } = useRouter()
  const [location] = useLocation()

  for (const routeElement of [props.children].flat()) {
    const { path, nest } = routeElement.props

    const match: Match = path
      ? matchRoute(parser, path, location, nest)
      : [true, {}]

    if (match[0]) {
      return (
        <LayoutComponent>
          {React.cloneElement(routeElement, { match })}
        </LayoutComponent>
      )
    }
  }

  return null
}
