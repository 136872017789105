import { observer } from 'mobx-react-lite'

import { useMst } from '@hooks'

import { CurrentScriptCard } from './CurrentScriptCard'
import { SelectedSideIcon } from './SelectedSideIcon'
import { ChooseSnapshotCard, SnapshotCard } from './SnapshotCard'
import { useSnapshotData } from './useSnapshotLandData'

export const LoadableSnapshotCard = ({
  scriptId,
  snapshotId,
  onClose,
  side,
  wrapped,
}: {
  scriptId: string
  snapshotId: string
  wrapped: boolean
  onClose?: Callback
  side?: LeftRight
}) => {
  const { data, isLoading, isError } = useSnapshotData({ scriptId, snapshotId })
  const editButtonMode = wrapped ? 'always' : 'hover'
  return (
    <SnapshotCard
      icon={side ? <SelectedSideIcon side={side} /> : null}
      data={data}
      sticky
      onClose={onClose}
      loading={isLoading}
      error={isError}
      editButton={data ? editButtonMode : undefined}
    />
  )
}

export const StickySnapshotCard = observer(function StickySnapshotCard({
  position,
  scriptId,
  wrapped,
}: {
  scriptId: string
  position: 'top' | 'bottom'
  wrapped: boolean
}) {
  const {
    snap1,
    snap2,
    currentView,
    dismissBottom,
    dismissTop,
    trackSnapshotEvent: trackEvent,
  } = useMst().view.snapshotLand
  const snapshotId = position === 'bottom' ? snap1 : snap2

  const side =
    currentView !== 'diff' ? undefined : position === 'top' ? 'left' : 'right'

  if (!(snapshotId && scriptId)) {
    return <ChooseSnapshotCard compare={currentView !== 'static'} />
  }

  const handleClose = () => {
    trackEvent('DISMISS_SELECTED_SNAPSHOT')
    if (position === 'top') {
      dismissTop()
    } else {
      dismissBottom()
    }
  }

  const onClose = currentView === 'static' ? undefined : handleClose

  if (snapshotId === 'current') {
    return (
      <CurrentScriptCard
        icon={side ? <SelectedSideIcon side={side} /> : null}
        sticky
        onClose={onClose}
      />
    )
  }

  return (
    <LoadableSnapshotCard
      wrapped={wrapped}
      side={side}
      scriptId={scriptId}
      snapshotId={snapshotId}
      onClose={onClose}
    />
  )
})
