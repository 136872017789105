import React from 'react'

import { useDrag } from 'react-dnd'

type DraggableItemProps = {
  enableDrag: boolean
  id: string
  children: React.ReactNode
}
export const DraggableItem = ({
  children,
  enableDrag,
  id,
}: DraggableItemProps) => {
  const draggableElementRef = React.useRef<HTMLDivElement>(null)

  const [{ isDragging }, connectDraggable] = useDrag(
    () => ({
      canDrag: enableDrag,
      type: 'script',
      item: { id },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    }),
    [enableDrag],
  )

  connectDraggable(draggableElementRef)

  return (
    <div
      ref={draggableElementRef}
      style={{
        opacity: isDragging ? 0.5 : 1,
        cursor: enableDrag ? 'move' : 'default',
        boxSizing: 'border-box',
        background: 'transparent',
      }}
    >
      {children}
    </div>
  )
}
