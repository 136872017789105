export type SideBySideContextOption = 'none' | 'some' | 'all'

export const VIEW_TYPES = ['static', 'asterisk', 'diff'] as const

export type SnapshotViewType = (typeof VIEW_TYPES)[number]

export function isViewType(value: unknown): value is SnapshotViewType {
  return (
    typeof value === 'string' && VIEW_TYPES.includes(value as SnapshotViewType)
  )
}
