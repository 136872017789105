import { PropsWithChildren } from 'react'

import { Route } from 'wouter'

import * as Staff from '@components/Staff'
import { CenteredScrollingBody } from '@layouts'

import { LayoutRoute } from './util'
import { GuardedRoute } from './util/GuardedRoute'

const StaffLayout = (props: PropsWithChildren) => (
  <GuardedRoute path="*" guard={(mst) => mst.user.staff}>
    <CenteredScrollingBody>{props.children}</CenteredScrollingBody>
  </GuardedRoute>
)

export const StaffRouter = () => (
  <LayoutRoute component={StaffLayout}>
    <Route path="/" component={Staff.LandingPage} />
    <Route path="/workspaces" component={Staff.Workspaces} />
    <Route path="/workspaces/:uuid" component={Staff.OrgDetail} />
    <Route path="/clone" component={Staff.CloneWorkspace} />
    <Route path="/invites" component={Staff.Invites} />
    <Route path="/templates" component={Staff.Templates} />
    <Route path="/users" component={Staff.Users} />
    <Route path="/users/:uuid" component={Staff.UserDetail} />
    <Route path="/formats" component={Staff.FormatEditor} />
    <Route path="/formats/:formatId" component={Staff.FormatDetail} />
    <Route path="/flags" component={Staff.BetaFlags} />
    <Route path="/rundowns" component={Staff.Rundowns} />
  </LayoutRoute>
)
