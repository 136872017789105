import { useState } from 'react'

import NiceModal from '@ebay/nice-modal-react'
import {
  Button,
  Group,
  LoadingOverlay,
  Modal,
  PinInput,
  Space,
  Stack,
  Text,
  TextInput,
} from '@mantine/core'
import { useForm } from '@mantine/form'

import { useModalControls } from '@components/Modals'
import { FormError } from '@components/Onboarding/FormErrors'
import { ThrottledBailoutLink } from '@components/Onboarding/OnboardingPage/BailoutLink'
import { Toast } from '@components/Toast'
import { useMst } from '@state'
import { formUtil, parseServerError } from '@util'

type FormValues = {
  email: string
  otp: string
}

export const StytchChangeEmail = () => {
  const controls = useModalControls()
  const { user, apiClient, doDebug } = useMst()
  const [oneTimePasscodeId, setOneTimePasscodeId] = useState('')
  const [opened, setOpened] = useState(true)
  const [error, setError] = useState<React.ReactNode | null>(null)
  const [loading, setLoading] = useState(false)

  const emailSubmitted = !!oneTimePasscodeId

  const form = useForm<FormValues>({
    initialValues: {
      email: '',
      otp: '',
    },
    validate: {
      email: (value) => {
        if (value.toLowerCase().trim() === user.email.toLowerCase().trim()) {
          return "That's already your email address!"
        }
        return formUtil.validateEmail(value)
      },
      otp: (value) => {
        if (oneTimePasscodeId) {
          return formUtil.validateOtpCode(value)
        }
      },
    },
  })

  const resendOtp = async () => {
    // clear the visible field, but not the email entered previously
    form.setFieldValue('otp', '')
    setError(null)
    setLoading(true)
    await requestOtp()
    setLoading(false)
  }

  const requestOtp = async () => {
    const result = await apiClient.initiateEmailChange({
      userId: user.id,
      email: form.values.email,
    })
    setOneTimePasscodeId(result.oneTimePasscodeId)
  }

  const reset = () => {
    form.reset()
    setError(null)
    setOneTimePasscodeId('')
  }

  const handleClose = () => {
    reset()
    setOpened(false)
    controls.onClose()
  }

  const handleSubmit = async ({ email, otp }: FormValues) => {
    setLoading(true)
    try {
      await doDebug()
      if (!emailSubmitted) {
        await requestOtp()
        // clear previous errors
        setError(null)
      } else {
        await apiClient.changeEmail({
          userId: user.id,
          code: otp,
          email,
          oneTimePasscodeId,
        })
        user.setEmail(email)
        handleClose()
      }
    } catch (e) {
      const { code, message } = parseServerError(e)
      setError(
        <Toast
          type="error"
          dismissable={false}
          message={<FormError code={code ?? message} onClick={resendOtp} />}
        />,
      )
      form.setFieldValue('otp', '')
    } finally {
      setLoading(false)
    }
  }

  const title = (emailSubmitted ? 'Verify' : 'Change') + ' Email Address'
  return (
    <Modal title={title} opened={opened} onClose={controls.onClose} size="sm">
      <LoadingOverlay visible={loading} />
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Stack>
          {emailSubmitted ? (
            <>
              <Text>
                We just sent a 6-digit verification code to&nbsp;
                <Text span fw="bold">
                  {form.getInputProps('email').value}
                </Text>
                . It expires in five minutes, so please check your email!
              </Text>
              {error}
              <Group justify="center">
                <Text fw="bold">Verification Code</Text>
                <PinInput
                  oneTimeCode
                  aria-label="One time code"
                  length={6}
                  {...form.getInputProps('otp')}
                  onComplete={() => handleSubmit(form.values)}
                />
              </Group>
            </>
          ) : (
            <>
              {error}
              <TextInput
                disabled={emailSubmitted}
                label="New email"
                {...form.getInputProps('email')}
              />
            </>
          )}
        </Stack>
        <Space h={40} />
        <Stack>
          <Group gap="xs" justify="center">
            <Button variant="filled" type="submit">
              {emailSubmitted ? 'Submit' : 'Next'}
            </Button>
            <Button variant="outline" onClick={handleClose}>
              Cancel
            </Button>
          </Group>
        </Stack>
        {emailSubmitted && (
          <>
            <Space h={20} />
            <ThrottledBailoutLink
              message="Didn't receive a code?"
              linkText="Resend"
              successText="Sent"
              onClick={resendOtp}
            />
          </>
        )}
      </form>
    </Modal>
  )
}

export const StytchChangeEmailModal = NiceModal.create(StytchChangeEmail)
export const showStytchChangeEmailModal = () =>
  NiceModal.show(StytchChangeEmailModal)
