import { Redirect, Route, Switch } from 'wouter'

import * as Views from '@components/Settings'

import { GuardedRoute } from './util/GuardedRoute'

export const SettingsRouter = () => (
  <Views.SettingsPage>
    <Switch>
      {/* Redirects for old routes that use "orgs" with orgId optional */}
      <Route path="/orgs/members/:orgId?">
        <Redirect to="/org/members" />
      </Route>
      <Route path="/orgs/permissions/:orgId?">
        <Redirect to="/org/permissions" />
      </Route>
      <Route path="/orgs/:orgId?">
        <Redirect to="/org" />
      </Route>

      <Route path="/developer" component={Views.DevSettings} />

      <Route path="/org/members" component={Views.MemberSettings} />

      {/* If the user isn't an admin and hits this path, redirect to settings root */}
      <GuardedRoute
        path="/org/permissions"
        guard={(root) => root.user.isCurrentOrgAdmin}
        fallback={<Redirect to="/" />}
        component={Views.PermissionSettings}
      />

      <Route path="/org" component={Views.OrgSettings} />

      <Route path="/security" component={Views.Security} />
      <Route path="/" component={Views.UserSettings} />
      {/* Last route is a fallback if nothing above matches */}
      <Redirect to="/" />
    </Switch>
  </Views.SettingsPage>
)
