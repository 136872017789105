import { types } from 'mobx-state-tree'

import { ScriptStatus, ScriptStatusMap } from '@showrunner/codex'

import { SharedScriptStatus } from '@state/types'

import type { IFolder, IOrgMember, RundownItem, ScriptItem } from '../types'

import { BaseModel } from './BaseModel'
import { IsoDate } from './IsoDate'

export const ListingBase = BaseModel.named('ListingBase')
  .props({
    id: types.union(types.string, types.number),
    name: types.string,
    contentsModifiedAt: IsoDate,
    contentsModifiedBy: types.string,
    createdAt: IsoDate,
    folderId: types.string,
    updatedAt: types.maybe(IsoDate),
  })
  .views((self) => ({
    get lastModifier(): IOrgMember | undefined {
      return self.rootStore.currentOrg?.getMember(self.contentsModifiedBy)
    },
    get parentFolder(): IFolder | undefined {
      return self.rootStore.folderMap.get(self.folderId)
    },
    get inTrash(): boolean {
      return !!this.parentFolder?.belongsToTrashTree
    },
    get isPrivate(): boolean {
      return !!this.parentFolder?.isPrivate
    },
    get isCurrentScript(): boolean {
      return !!(self.id === self.rootStore.currentScript?.id)
    },
    get trashFolder(): IFolder | undefined {
      return this.isPrivate
        ? self.rootStore.rootFolders.privateTrash
        : self.rootStore.rootFolders.sharedTrash
    },
  }))
  .actions((self) => ({
    updateFields({
      name,
      updatedAt,
      contentsModifiedAt,
      contentsModifiedBy,
    }: Partial<{
      name: string
      updatedAt: Date
      contentsModifiedAt: Date
      contentsModifiedBy: string
    }>) {
      if (name) self.name = name
      if (updatedAt) self.updatedAt = updatedAt
      if (contentsModifiedAt) self.contentsModifiedAt = contentsModifiedAt
      if (contentsModifiedBy) self.contentsModifiedBy = contentsModifiedBy
    },
  }))

export const ScriptListingBase = ListingBase.named('ScriptListingBase')
  .props({
    id: types.identifier,
    // The goal is to turn this into just LIMITED and OPEN
    status: types.enumeration<ScriptStatus>(Object.values(ScriptStatusMap)),
  })
  .views((self) => ({
    get accessLevel(): ScriptStatus {
      return self.isPrivate ? 'PRIVATE' : self.status
    },
    // right now the only reason a user cant comment on a script is when its in the trash
    // but in a world with more fine-grained permissions, that will change
    get canAddComments(): boolean {
      return !self.inTrash
    },
    get isFavorite(): boolean {
      return !!self.rootStore.currentOrg?.favoriteItemIds.includes(self.id)
    },
    get openedAt(): Date | undefined {
      const match = self.rootStore.currentOrg?.myHistory.find(
        ([, listingId]) => listingId === self.id,
      )
      return match ? new Date(match[0]) : undefined
    },
  }))
  .actions((self) => ({
    setSharedStatus(value: SharedScriptStatus) {
      self.status = value

      if (self.isCurrentScript) {
        const { currentScript } = self.rootStore
        currentScript?.setPmEditability(currentScript?.isEditable ?? false)
      }
    },
    setFolderId(newFolderId: string) {
      const oldFolderId = self.folderId
      if (newFolderId !== oldFolderId) {
        self.folderId = newFolderId
      }
    },
    updateFromItem(item: ScriptItem) {
      self.updateFields(item)
      this.setSharedStatus(item.status)
      this.setFolderId(item.parentFolderId)
    },
    // little hack so we pretend the api NEVER sends us
    // a status of private
    afterAttach() {
      if (self.isPrivate) {
        self.status = 'OPEN'
      }
    },
  }))

export const RundownListingBase = ListingBase.named('RundownListingBase')
  .props({
    id: types.identifierNumber,
    uuid: types.string,
  })
  .views((self) => ({
    get isFavorite(): boolean {
      return !!self.rootStore.currentOrg?.favoriteItemIds.includes(self.uuid)
    },
    get openedAt(): Date | undefined {
      const match = self.rootStore.currentOrg?.myHistory.find(
        ([, listingId]) => listingId === self.uuid,
      )
      return match ? new Date(match[0]) : undefined
    },
  }))
  .actions((self) => ({
    setFolderId(newFolderId: string) {
      const oldFolderId = self.folderId
      if (newFolderId !== oldFolderId) {
        self.folderId = newFolderId
      }
    },
    updateFromItem(item: RundownItem) {
      self.updateFields(item)
      this.setFolderId(item.parentFolderId)
    },
  }))
