import { types } from 'mobx-state-tree'

import { schemas } from '@showrunner/scrapi'

import {
  isViewType,
  type SideBySideContextOption,
  type SnapshotViewType,
} from '@components/SnapshotLand'
import { MixpanelEventName } from '@util/mixpanel/eventNames'
import { SOME_DIFF_CONTEXT_LINES } from '@util/printing'
import { ScriptJson, SnapshotSummary } from '@util/ScriptoApiClient/types'

import { BaseModel } from '../BaseModel'

// these are query param keys
const VIEW = 'view'
const SNAPSHOT1 = 'snap1'
const SNAPSHOT2 = 'snap2'

// We've gone back and forth over whether the left snapshot in a side-by-side
// view should be the newer or older-- so, the params we use and the meanings
// of the sides should not be assumed. The map is handled here
type SnapKey = typeof SNAPSHOT1 | typeof SNAPSHOT2
const SideParamMap: Record<LeftRight, SnapKey> = {
  left: SNAPSHOT2,
  right: SNAPSHOT1,
}

// in a URL search we use snap1 and snap2 as keys and the values
// look like <snapshot-id> OR <snapshot-id>_<block-id>
type SnapParam = {
  snapshotId: string
  blockId?: string
}
const parseSnapQueryParam = (
  rawValue: string | undefined,
): SnapParam | undefined => {
  if (rawValue) {
    const [snapshotId, blockId] = rawValue.split('_')
    const isValidSnapshot =
      snapshotId === 'current' || schemas.Uuid.safeParse(snapshotId).success

    if (isValidSnapshot) {
      const result: SnapParam = { snapshotId }
      if (schemas.Uuid.safeParse(blockId).success) {
        result.blockId = blockId
      }
      return result
    }
  }
}

// This handles state for the script history overlay. However,
// we do not store the actual snapshot data in MST, that's all
// done in tanstack. This is just for managing the view state,
// query params, etc.
export const SnapshotLandState = BaseModel.named('SnapshotLand')
  .props({
    monochrome: false,
    snapshotMap: types.map(types.frozen<SnapshotSummary>()),
    totalSnapshots: 0,
    isFetchingSnapshots: false,
    hasNewer: false,
    hasOlder: false,
    // we cache data for snapshots relative to a scriptId. When swapping scriptIds
    // we need to dump the cached data. This value tracks which scriptId any
    // cached data is associated with
    scriptId: '',
    scriptFetchedAt: types.maybe(types.frozen<Date>()),
    scriptVersion: 0,
    scriptDoc: types.maybe(types.frozen<ScriptJson>()),
  })
  .views((self) => ({
    get currentView(): SnapshotViewType {
      const param = self.rootStore.location.getQueryParam(VIEW)
      if (isViewType(param)) {
        return param
      }
      return 'static'
    },
    // the raw value xxx of `?snap1=xxxxx`
    get rawSnap1(): string | undefined {
      return self.rootStore.location.getQueryParam(SNAPSHOT1)
    },
    get rawSnap2(): string | undefined {
      return self.rootStore.location.getQueryParam(SNAPSHOT2)
    },
    // parse the raw query param value into a snapId & possibly a blockId
    get snapAndBlock1(): SnapParam | undefined {
      return parseSnapQueryParam(this.rawSnap1)
    },
    get snapAndBlock2(): SnapParam | undefined {
      return parseSnapQueryParam(this.rawSnap2)
    },
    // just the ID from the query param
    get snap1(): string | undefined {
      return this.snapAndBlock1?.snapshotId
    },
    get snap2(): string | undefined {
      return this.snapAndBlock2?.snapshotId
    },
    get sortedHistory() {
      return Array.from(self.snapshotMap.values()).sort(
        (s2, s1) => s1.version - s2.version,
      )
    },
    get filter(): 'all' | 'manual' {
      return self.rootStore.user.prefs.snapshotFilter ?? 'all'
    },
    get isFetchingFirstPage() {
      return self.isFetchingSnapshots && self.snapshotMap.size === 0
    },
    get isFetchingNextPage() {
      return self.isFetchingSnapshots && self.snapshotMap.size > 0
    },
    get mostRecentSnapshot(): SnapshotSummary | null {
      if (
        !self.scriptId ||
        this.isFetchingFirstPage ||
        this.sortedHistory.length === 0
      ) {
        return null
      }
      return this.sortedHistory[0]
    },

    get pageSize(): number {
      if (self.rootStore.view.isDebugEnabled('small-page')) {
        return 5
      }
      return 50
    },
    scriptQueryKey(scriptId: string) {
      return ['snapshotland-script', scriptId]
    },
    // we need the query key in the components but we need it here when
    // we update a snapshot name to be able to update the value
    snapshotQueryKey({
      scriptId,
      snapshotId,
    }: {
      scriptId: string
      snapshotId: string
    }) {
      return ['snapshotland-snapshot', { scriptId, snapshotId }]
    },
    get sideBySideContextLines(): number | null {
      const pref: SideBySideContextOption =
        self.rootStore.user.prefs.snapshotLinesOfContext ?? 'some'
      return pref === 'none'
        ? 0
        : pref === 'all'
          ? null
          : SOME_DIFF_CONTEXT_LINES
    },
  }))
  .actions((self) => ({
    setFetching(value: boolean) {
      self.isFetchingSnapshots = value
    },
    setHasNewer(value: boolean) {
      self.hasNewer = value
    },
    setHasOlder(value: boolean) {
      self.hasOlder = value
    },
    setTotal(value: number) {
      self.totalSnapshots = value
    },
    setScriptDoc(value: ScriptJson) {
      self.scriptDoc = value
    },
    setScriptFetchedAt(value: Date) {
      self.scriptFetchedAt = value
    },
    setScriptVersion(value: number) {
      self.scriptVersion = value
    },
    setFilter(snapshotFilter: 'all' | 'manual') {
      if (self.filter !== snapshotFilter) {
        self.rootStore.user.updatePreferences({
          snapshotFilter,
        })

        this.clearCachedData()
        if (self.scriptId) {
          this.fetchSnapshots(self.scriptId)
        }
      }
    },
    selectSlene(sleneId: string, side: LeftRight) {
      const key = SideParamMap[side]
      const snapId = key === 'snap1' ? self.snap1 : self.snap2
      if (snapId) {
        self.rootStore.location.updateQueryParams({
          [key]: `${snapId}_${sleneId}`,
        })
      }
    },
    deselectSlene(side: LeftRight) {
      const key = SideParamMap[side]
      const snapId = key === 'snap1' ? self.snap1 : self.snap2
      if (snapId) {
        self.rootStore.location.updateQueryParams({
          [key]: snapId,
        })
      }
    },
    selectView(view: string) {
      if (isViewType(view)) {
        self.rootStore.location.updateQueryParams({ view })
        self.rootStore.user.updatePreferences({ snapshotTab: view })
      }
    },
    selectSnapshotUrl(id: string): string {
      const { currentView, snap1, snap2 } = self
      // if the view is static, then we swap out snapshot1,
      // otherwise, we look for an empty slot and if none exist
      // we do nothing
      const currentUrl = new URL(window.location.href)
      if (currentView === 'static' || !snap1) {
        currentUrl.searchParams.set(SNAPSHOT1, id)
      } else if (!snap2) {
        currentUrl.searchParams.set(SNAPSHOT2, id)
      }
      return currentUrl.toString()
    },
    dismissSnapshot(position: 1 | 2) {
      const key = position === 1 ? SNAPSHOT1 : SNAPSHOT2
      self.rootStore.location.updateQueryParams({ [key]: null })
    },
    dismissTop() {
      this.dismissSnapshot(2)
    },
    dismissBottom() {
      // we shuffle the remaining snapshot into slot 1 when present to
      // ensure it will be visible if/when folks navigate away from a comparison route
      const snap1 = self.rawSnap2 ?? null
      self.rootStore.location.updateQueryParams({ [SNAPSHOT1]: snap1 })
      this.dismissSnapshot(2)
    },
    swapSnapshots() {
      const { rawSnap1, rawSnap2 } = self
      if (rawSnap1 && rawSnap2) {
        self.rootStore.location.updateQueryParams({
          [SNAPSHOT1]: rawSnap2,
          [SNAPSHOT2]: rawSnap1,
        })
      }
    },
    setSideBySideContextLines(value: SideBySideContextOption) {
      self.rootStore.user.updatePreferences({
        snapshotLinesOfContext: value,
      })
    },
    setMonochrome(value: boolean) {
      self.monochrome = value
    },
    clearCachedData() {
      self.totalSnapshots = 0
      self.snapshotMap.clear()
      self.isFetchingSnapshots = false
      self.hasNewer = false
      self.hasOlder = false
      self.scriptDoc = undefined
      self.scriptFetchedAt = undefined
      self.scriptVersion = 0
    },
    setScriptId(value: string) {
      self.scriptId = value
    },
    addSnapshots(results: SnapshotSummary[]) {
      results.forEach((summary) => {
        self.snapshotMap.set(summary.id, summary)
      })
    },
    async fetchSnapshots(scriptId: string) {
      // if already fetching for that script, exit early
      if (self.isFetchingSnapshots && self.scriptId === scriptId) {
        return
      }

      // if we are changing scripts, we need to clear out any cached data
      if (self.scriptId !== scriptId) {
        this.clearCachedData()
        this.setScriptId(scriptId)
      }

      this.setFetching(true)
      try {
        await self.rootStore.doDebug()
        const { total, results, range } =
          await self.apiClient.fetchSnapshotHistory({
            scriptId,
            from: self.snapshotMap.size,
            size: self.pageSize,
            filter: self.filter,
          })
        // make sure we are still in scope for that script
        if (self.scriptId === scriptId) {
          this.setTotal(total)
          this.addSnapshots(results)
          // we rely on the server-reported range to know if there are
          // older snapshots we could load
          const isLastPage = range[1] + 1 >= total
          this.setHasOlder(!isLastPage)
        }
      } finally {
        this.setFetching(false)
      }
    },
    // when entering snapshotland, we call this. Since it's called from
    // a component, we use the cached scriptId to know whether or not
    // to kick off a query
    initializeForScript(scriptId: string) {
      if (self.scriptId !== scriptId) {
        this.fetchSnapshots(scriptId)
      }
    },

    tearDownSnapshotLand() {
      this.clearCachedData()
      self.scriptId = ''
    },

    updateCachedSnapName({
      scriptId,
      snapshotId,
      name,
    }: {
      scriptId: string
      snapshotId: string
      name: string
    }) {
      const cachedHistoryItem = self.snapshotMap.get(snapshotId)
      if (cachedHistoryItem) {
        self.snapshotMap.set(snapshotId, {
          ...cachedHistoryItem,
          name,
        })
      }
      // if the snapshot is in the tanstack cache, we want to update it there
      // as well
      const queryKey = self.snapshotQueryKey({ scriptId, snapshotId })
      const cachedSnapshot =
        self.environment.queryClient.getQueryData<SnapshotSummary>(queryKey)
      if (cachedSnapshot) {
        self.environment.queryClient.setQueryData(queryKey, {
          ...cachedSnapshot,
          name,
        })
      }
    },

    async renameSnapshot(opts: {
      scriptId: string
      snapshotId: string
      name: string
    }) {
      await self.rootStore.doDebug()
      await self.apiClient.updateSnapshot(opts)
      this.updateCachedSnapName(opts)
    },

    async checkForNewer(scriptId: string) {
      const { total, results } = await self.apiClient.fetchSnapshotHistory({
        scriptId,
        from: 0,
        size: 1,
        filter: self.filter,
      })
      if (self.scriptId === scriptId) {
        this.setTotal(total)
        if (results.length > 0) {
          const snap = results[0]
          const hasNewer = !self.snapshotMap.has(snap.id)
          // dont reset when the script has changed but no new snapshots exist
          if (hasNewer) this.setHasNewer(hasNewer)
        }
      }
    },

    trackSnapshotEvent(
      eventName: MixpanelEventName,
      context?: { [key: string]: unknown },
    ) {
      const { currentView } = self
      self.trackEvent(eventName, {
        topic: 'snapshot-land',
        currentView,
        ...context,
      })
    },
  }))
