import { Anchor, Text } from '@mantine/core'

import { schemas } from '@showrunner/scrapi'

import { NavAnchor } from '@components/NavAnchor'
import { useMst } from '@state'
import { knownStytchErrorCodes, userFacingStytchError } from '@util/stytch'

export const knownCodes = schemas.AuthErrorCode.Enum

export const EmailInUse = () => {
  const { location, loggedIn } = useMst()

  return (
    <Text>
      This email is already in use.
      {!loggedIn && (
        <>
          <br />
          <NavAnchor href={location.pathWithCurrentSearch('/login')}>
            Try signing in?
          </NavAnchor>
        </>
      )}
    </Text>
  )
}

export const IncorrectOtp = ({ onClick }: { onClick?: () => void }) => {
  return (
    <Text>
      This code is expired.
      <br />
      <Anchor onClick={onClick}>Send a new one?</Anchor>
    </Text>
  )
}

export const StartOver = ({ onClick }: { onClick?: () => void }) => {
  return (
    <Text>
      For security reasons, this session
      <br />
      timed out. You need to&nbsp;
      <Anchor onClick={onClick}>start over.</Anchor>
    </Text>
  )
}

export type ServerErrorCode =
  | 'invalidCredentials'
  | 'noAccount'
  | 'mustResetPassword'
  | 'tooManyRequests'
  | 'undeliverableEmail'
  | 'unknown'

export const serverErrorMessages: Record<ServerErrorCode, React.ReactNode> = {
  invalidCredentials: 'Invalid credentials',
  noAccount: 'No account found with that email address',
  mustResetPassword: (
    <Text>
      For security reasons, you will need to&nbsp;
      <NavAnchor href="/forgot-password">reset your password</NavAnchor>
    </Text>
  ),
  tooManyRequests: (
    <Text>
      Uh oh! Too many login attempts. Please&nbsp;
      <NavAnchor href="/forgot-password">reset your password</NavAnchor>, or
      wait a while and try again.
    </Text>
  ),
  undeliverableEmail: 'This email address is unreachable',
  unknown: 'An unknown server error occurred',
}

export const FormError = ({
  code,
  onClick,
}: {
  code?: string
  onClick?: () => void
}) => {
  const stytchCode = knownStytchErrorCodes.safeParse(code)

  if (stytchCode.success) {
    return <Text>{userFacingStytchError(stytchCode.data)}</Text>
  }

  switch (code) {
    case knownCodes.EMAIL_ALREADY_IN_USE:
      return <EmailInUse />

    case knownCodes.INCORRECT_PASSCODE:
      return <Text>That code isn&apos;t valid. Try again!</Text>

    case knownCodes.OTP_EXPIRED_OR_USED:
      return <IncorrectOtp onClick={onClick} />

    case knownCodes.SESSION_NOT_FOUND:
      return <StartOver onClick={onClick} />

    case knownCodes.WEAK_PASSWORD:
      return (
        <Text>
          Oh no! This password is on a list of logins that have been compromised
          by hackers. You&apos;ll have to use a different password.
        </Text>
      )

    case knownCodes.INVALID_CREDENTIALS:
      return <Text>That&apos;s not your current password! Try again.</Text>

    case knownCodes.UNDELIVERABLE_EMAIL:
      return <Text>{serverErrorMessages.undeliverableEmail}</Text>

    default:
      return <Text>An unknown error occurred</Text>
  }
}
