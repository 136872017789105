import React, { ReactNode } from 'react'

import {
  Button,
  Divider,
  Group,
  Loader,
  ScrollArea,
  Stack,
  Text,
} from '@mantine/core'
import { useElementSize } from '@mantine/hooks'
import cn from 'classnames'
import { observer } from 'mobx-react-lite'

import { FaIcon } from '@components/FaIcon'
import { useMst } from '@hooks'
import { SnapshotSummary } from '@util/ScriptoApiClient/types'

import { CurrentScriptCard } from './CurrentScriptCard'
import {
  headerDateStr,
  isSnapshotSelectable,
  isSnapshotSelected,
} from './helpers'
import { SelectionMode, SnapshotCard } from './SnapshotCard'
import { SnapshotFilter } from './SnapshotFilter'
import { StickySnapshotCard } from './StickySnapshotCard'
import { type SnapshotViewType } from './types'
import { useSelectedSnapshots } from './useSnapshotLandData'

import styles from './SnapshotList.module.scss'

// if s1 (right side/bottom) is older chronologically
// or the same version but older temporally, swap them
const shouldSwapSnapshots = (
  s1: SnapshotSummary | null | undefined,
  s2: SnapshotSummary | null | undefined,
): boolean => {
  if (!s1 || !s2) return false
  // a higher version number is indicative of a more recent snapshot
  if (s1.version > s2.version) return false
  if (s1.version < s2.version) return true
  // two snapshots can share the same version if one is an autosave and the other isnt
  return s1.createdAt.valueOf() < s2.createdAt.valueOf()
}

// if the history list is narrow, we show the cards top/bottom
const SIDE_BY_SIDE_STICKY_THRESHOLD = 600

const StickyCards = ({
  mode,
  scriptId,
  width,
}: {
  scriptId: string
  mode: SelectionMode
  width: number
}) => {
  const wrapped = width < SIDE_BY_SIDE_STICKY_THRESHOLD
  return (
    <div
      className={cn(styles.snapshotList_selectedSection, {
        [styles.wrapped]: wrapped,
      })}
    >
      {mode === 'comparison' && (
        <StickySnapshotCard
          wrapped={wrapped}
          position="top"
          scriptId={scriptId}
        />
      )}
      <StickySnapshotCard
        wrapped={wrapped}
        position="bottom"
        scriptId={scriptId}
      />
    </div>
  )
}

const HEADERS: Record<SnapshotViewType, string> = {
  static: 'Snapshots',
  asterisk: 'Snapshot comparison',
  diff: 'Snapshot comparison',
}

const SnapshotListHeader = (
  props: React.PropsWithChildren<{ scriptId: string }>,
) => (
  <Group
    wrap="nowrap"
    justify="space-between"
    align="center"
    className={styles.snapshotList_header}
    gap={5}
    pb={10}
    px={10}
    pt={10}
  >
    <Text lineClamp={1} style={{ wordBreak: 'break-all' }} fw="bold">
      {props.children}
    </Text>
    <SnapshotFilter scriptId={props.scriptId} />
  </Group>
)

const DateSeparator = ({
  snap,
  previousSnap,
}: {
  snap: SnapshotSummary
  previousSnap?: SnapshotSummary
}) => {
  const snapHeader = headerDateStr(snap)

  if (previousSnap && snapHeader === headerDateStr(previousSnap)) {
    return null
  }

  return (
    <Text tt="uppercase" fw="bold" size="12" c="gray">
      {snapHeader}
    </Text>
  )
}

const LoadMoreControls = ({
  hasMore,
  onClick,
  loading,
  showing,
  total,
}: {
  hasMore: boolean
  showing: number
  total: number
  onClick: Callback
  loading?: boolean
}) => {
  const label = hasMore ? `${showing} of ${total}` : `showing all ${total}`

  return (
    <Stack align="center">
      <Text c="gray.6" fw="bold" size="16">
        {label}
      </Text>
      {hasMore && (
        <Group justify="center" pt={10}>
          <Button
            size="xs"
            variant="subtle"
            onClick={onClick}
            loading={loading}
          >
            Load More
          </Button>
        </Group>
      )}
    </Stack>
  )
}

const NoSnapshots = () => (
  <Stack pl={10} pr={5} pt={10}>
    <Text fw="bold">No snapshots yet</Text>
    <Text>
      Snapshots let you view and compare different versions of a script to see
      what&apos;s changed.
    </Text>
    <Text>
      To create a snapshot, you&apos;ll first need to close out of this view.
      Then, from the editor, click the&nbsp;
      <span className={styles.snapshotList_emptyButton}>
        <FaIcon span c="dark.9" icon="fa-camera" size="14" />
        &nbsp;
        <Text span fw="bold">
          Snapshot
        </Text>
      </span>
      &nbsp;button on the right side of the toolbar.
    </Text>
  </Stack>
)

export const SnapshotListEntries = observer<{
  children(snapshot: SnapshotSummary): ReactNode
  includeCurrScript?: boolean
}>(({ includeCurrScript = false, children }) => {
  const {
    scriptId,
    sortedHistory,
    isFetchingFirstPage,
    isFetchingNextPage,
    hasOlder,
    fetchSnapshots,
    totalSnapshots,
    trackSnapshotEvent: trackEvent,
  } = useMst().view.snapshotLand

  return (
    <ScrollArea className={styles.snapshotList_scroller}>
      <Stack gap={10} className={styles.snapshotList_contents}>
        {isFetchingFirstPage && (
          <Group justify="center">
            <Loader size="sm" />
          </Group>
        )}
        {includeCurrScript && (
          <>
            <CurrentScriptCard />
            <Divider />
          </>
        )}
        {sortedHistory.map((snapshot, idx) => {
          return (
            <React.Fragment key={snapshot.id}>
              <DateSeparator
                snap={snapshot}
                previousSnap={sortedHistory[idx - 1]}
              />
              {children(snapshot)}
            </React.Fragment>
          )
        })}

        {sortedHistory.length > 0 && (
          <LoadMoreControls
            hasMore={hasOlder}
            loading={isFetchingNextPage}
            onClick={() => {
              trackEvent('LOAD_MORE_SNAPSHOTS')
              fetchSnapshots(scriptId)
            }}
            total={totalSnapshots}
            showing={sortedHistory.length}
          />
        )}

        {sortedHistory.length === 0 && !isFetchingFirstPage && <NoSnapshots />}
      </Stack>
    </ScrollArea>
  )
})

export const SnapshotList = observer(function SnapshotList({
  scriptId,
}: {
  scriptId: string
}) {
  const {
    snap1,
    snap2,
    currentView,
    selectSnapshotUrl,
    swapSnapshots,
    dismissSnapshot,
    trackSnapshotEvent: trackEvent,
  } = useMst().view.snapshotLand
  const { ref: sizeRef, width } = useElementSize()

  const { topQuery, bottomQuery } = useSelectedSnapshots(scriptId)

  React.useEffect(() => {
    if (shouldSwapSnapshots(topQuery?.data, bottomQuery?.data)) {
      swapSnapshots()
    }
  }, [bottomQuery.data, topQuery.data, swapSnapshots, snap1, snap2])

  // if the same snapshot is selected twice, then dismiss the second
  // copy. This can happen when switching views
  React.useEffect(() => {
    if (snap1 && snap1 === snap2) {
      dismissSnapshot(2)
    }
  })

  return (
    <Stack gap={0} className={styles.snapshotList} ref={sizeRef}>
      <SnapshotListHeader scriptId={scriptId}>
        {HEADERS[currentView]}
      </SnapshotListHeader>
      <StickyCards
        scriptId={scriptId}
        mode={currentView === 'static' ? 'solo' : 'comparison'}
        width={width}
      />
      <SnapshotListEntries includeCurrScript>
        {(snapshot) => {
          const opts = { snap1, snap2, currentView, snapshotId: snapshot.id }
          const isSelected = isSnapshotSelected(opts)
          const selectable = isSnapshotSelectable(opts)

          return (
            <SnapshotCard
              data={snapshot}
              selected={isSelected}
              editButton="hover"
              href={selectable ? selectSnapshotUrl(snapshot.id) : undefined}
              onClick={
                selectable ? () => trackEvent('SELECT_SNAPSHOT') : undefined
              }
            />
          )
        }}
      </SnapshotListEntries>
    </Stack>
  )
})
