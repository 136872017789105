import { Plugin } from 'prosemirror-state'

import { DEFAULT_CONTD_DISPLAY_TEXT } from '@util'

import {
  characterContdsPluginKey,
  getContdInventory,
  getDecorations,
  Inventory,
} from './helpers'

// the most convoluted way to supply a plain string yet devised
type PluginParams = {
  script: {
    scriptFormat: {
      definition: { moresContds?: { contdDisplayText?: string } }
    }
  }
}

export const characterContdsPlugin = ({
  script: {
    scriptFormat: { definition },
  },
}: PluginParams) => {
  const displayText =
    definition.moresContds?.contdDisplayText ?? DEFAULT_CONTD_DISPLAY_TEXT

  return new Plugin<Inventory>({
    state: {
      init(_, state) {
        return getContdInventory(state)
      },
      apply(_, pluginState, oldState, newState) {
        return oldState.doc.eq(newState.doc)
          ? pluginState
          : getContdInventory(newState)
      },
    },
    key: characterContdsPluginKey,
    props: {
      decorations(state) {
        return getDecorations({ state, displayText })
      },
    },
  })
}
