import {
  isEqual,
  isBefore as isLessRecent,
  isAfter as isMoreRecent,
} from 'date-fns'

import { IListing, ItemPaging } from '@state/types'
import { ScrapiClient } from '@util'
import { SortOrder } from '@util/LocalPersistence'
import {
  aIsDefinitelyAfterBInPostgres,
  toPostgresLower,
} from '@util/pgCollation'

// At the time we make a listings request, the last
// record returned is used for subsequent queries to load
// more.  Since the listing might change through socket updates,
// we grab a snapshot that looks like this
export type CursorRecord = {
  uuid: string
  name: string
  contentsModifiedAt: Date
  createdAt: Date
  updatedAt: Date
}

export const sortOrderToDirection: Record<SortOrder, 'asc' | 'desc'> = {
  recent: 'desc',
  alphabetical: 'asc',
  newest: 'desc',
  oldest: 'asc',
}

const dateForSortOrder = (
  sortOrder: Exclude<SortOrder, 'alphabetical'>,
  item: IListing | CursorRecord,
): Date => {
  switch (sortOrder) {
    case 'newest':
    case 'oldest':
      return item.createdAt
    case 'recent':
      return item.contentsModifiedAt
  }
}

export const buildPagingParam = ({
  sortOrder,
  cursorRecord,
  pageSize,
}: {
  sortOrder: SortOrder
  cursorRecord?: CursorRecord
  pageSize: number
}): ItemPaging => {
  const direction = sortOrderToDirection[sortOrder]

  switch (sortOrder) {
    case 'alphabetical':
      return {
        direction,
        orderBy: 'name',
        pageSize,
        cursor: cursorRecord
          ? { name: cursorRecord.name, id: cursorRecord.uuid }
          : undefined,
      }
    case 'newest':
      return {
        direction,
        orderBy: 'createdAt',
        pageSize,
        cursor: cursorRecord
          ? { date: cursorRecord.createdAt, id: cursorRecord.uuid }
          : undefined,
      }
    case 'oldest':
      return {
        direction,
        orderBy: 'createdAt',
        pageSize,
        cursor: cursorRecord
          ? { date: cursorRecord.createdAt, id: cursorRecord.uuid }
          : undefined,
      }
    case 'recent':
      return {
        direction,
        orderBy: 'contentsModifiedAt',
        pageSize,
        cursor: cursorRecord
          ? { date: cursorRecord.contentsModifiedAt, id: cursorRecord.uuid }
          : undefined,
      }
  }
}

export const isPastCursor = (
  cursor: CursorRecord,
  listing: IListing,
  sortOrder: SortOrder,
): boolean => {
  const direction = sortOrderToDirection[sortOrder]

  // start with all the conditions where the primary sort is not equal
  if (
    sortOrder === 'alphabetical' &&
    !(toPostgresLower(listing.name) === toPostgresLower(cursor.name))
  ) {
    // this is permissive, it might return stuff that's out of order
    // but it will only be differences in non-alphanumeric letters that
    // causes it to be so
    return aIsDefinitelyAfterBInPostgres(listing.name, cursor.name)
  }

  if (sortOrder !== 'alphabetical') {
    const listingDate = dateForSortOrder(sortOrder, listing)
    const cursorDate = dateForSortOrder(sortOrder, cursor)
    if (!isEqual(listingDate, cursorDate)) {
      // if we're sorting asc, a listing is past the cursor if
      // it is more recent
      if (direction === 'asc') {
        return isMoreRecent(listingDate, cursorDate)
      } else {
        return isLessRecent(listingDate, cursorDate)
      }
    }
  }

  // at this point, we know that the listing and cursor have equal values on the
  // sort by field so we want to use the ID as a tiebreaker
  if (direction === 'asc') {
    return listing.uuid > cursor.uuid
  }

  return listing.uuid < cursor.uuid
}

export const pagingMatches = (a: ItemPaging, b: ItemPaging): boolean => {
  if (!!a.cursor !== !!b.cursor) {
    return false
  }
  return a.direction === b.direction && a.orderBy === b.orderBy
}

export const isPagingStillValid = ({
  currentSortOrder,
  currentCursor,
  fetchedPaging,
}: {
  currentSortOrder: SortOrder
  currentCursor?: CursorRecord
  fetchedPaging: ItemPaging
}) => {
  // create a dummy paging so we can compare
  const newPaging = buildPagingParam({
    sortOrder: currentSortOrder,
    cursorRecord: currentCursor,
    pageSize: 1,
  })

  // either both need to have a cursor or neither
  const currentHasCursor = !!newPaging.cursor
  const fetchedHasCursor = !!fetchedPaging.cursor
  if (currentHasCursor !== fetchedHasCursor) {
    return false
  }

  return (
    newPaging.direction === fetchedPaging.direction &&
    newPaging.orderBy === fetchedPaging.orderBy
  )
}

// this queryFn is shared by some mst side effects and
// hooks. By ensuring the same queryKey we can rely on
// react-query to do the right thing with the cache
export const buildFolderListingsQuery = ({
  scrapi,
  sortOrder,
  cursorRecord,
  folderId,
  pageSize,
}: {
  scrapi: ScrapiClient
  sortOrder: SortOrder
  folderId: string
  cursorRecord?: CursorRecord
  pageSize: number
}) => {
  const paging = buildPagingParam({ sortOrder, cursorRecord, pageSize })
  const queryFn = () =>
    scrapi.folders.listItems({
      params: { id: folderId },
      body: {
        paging,
        filter: {
          itemTypes: ['script', 'rundown'],
        },
      },
    })
  const queryKey = ['folder-listings', folderId, sortOrder, cursorRecord]
  return { queryFn, queryKey, paging }
}

// we fetch folder summaries at most once every 5 seconds
// unless a socket event comes in. then we invalidate the query surgically
// and refetch immediately
export const getFolderSummaryQuery = ({
  scrapi,
  folderId,
}: {
  scrapi: ScrapiClient
  folderId: string
}) => {
  const queryFn = () => scrapi.folders.getSummary({ params: { id: folderId } })
  const queryKey = getFolderSummaryQueryKey(folderId)
  const staleTime = 5 * 1000
  return { queryFn, queryKey, staleTime }
}

export const getFolderSummaryQueryKey = (folderId: string) => [
  'folder-summary',
  folderId,
]
