import { Loader, Tooltip } from '@mantine/core'
import { observer } from 'mobx-react-lite'

import { FaIcon } from '@components/FaIcon'
import { StatusBar } from '@components/StatusBar'
import { useMst } from '@state'

import { InkMessagesMenu } from './InkMessagesMenu'
import { PageCount } from './PageCount'
import { PagelessToggle } from './PagelessToggle'
import { RundownLinkIcon } from './RundownLinkIcon'
import { ScriptStatusButton } from './ScriptStatusButton'
import { SyntaxHighlighting } from './SyntaxHighlighting'
import { showViewPreferencesModal } from './ViewPreferencesModal'
import { WrapText } from './WrapText'
import { ZoomMenu } from './ZoomMenu'

export const ScriptStatusBar = observer(function ScriptStatusBar() {
  const {
    currentOrg,
    currentRundown,
    currentScript,
    user,
    currentInkProject,
    view,
  } = useMst()
  if (!currentScript) return null

  const { isInk, isLinkedToRundown, isPrivate } = currentScript
  const { pageCount } = currentScript
  const { pageless } = user.prefs
  const showScriptStatus = isPrivate || currentOrg?.hasLimitedScriptsEnabled
  const maxMenuHeight = view.dimensions.scriptScroller.height - 20

  const showPageCount = !isInk && !pageless && pageCount
  const showCompilationStatus = isInk && currentInkProject

  return (
    <StatusBar>
      <StatusBar.Section>
        {!isInk && (
          <PagelessToggle
            isPageless={pageless}
            onEnablePageless={user.enablePagelessMode}
            onDisablePageless={user.disablePagelessMode}
          />
        )}
        <ZoomMenu />
        {isInk && <WrapText />}
        <StatusBar.ActionIcon
          onClick={showViewPreferencesModal}
          tooltip="View preferences"
          btnVariant="subtle"
          icon={<FaIcon icon="fa-user-gear" />}
        />
        {isInk && <SyntaxHighlighting />}
      </StatusBar.Section>
      <StatusBar.Section>
        {currentRundown && <RundownLinkIcon isLinked={isLinkedToRundown} />}
        {showPageCount && <PageCount count={pageCount} />}
        {showCompilationStatus && (
          <>
            {currentInkProject.status === 'working' && (
              <Tooltip label="Compiling...">
                <div>
                  <Loader size="xs" color="yellow" />
                </div>
              </Tooltip>
            )}
            <InkMessagesMenu
              maxHeight={maxMenuHeight}
              inkProject={currentInkProject}
            />
          </>
        )}
        {showScriptStatus && (
          <ScriptStatusButton
            readOnly={!user.canChangeScriptStatus}
            script={currentScript}
          />
        )}
      </StatusBar.Section>
    </StatusBar>
  )
})
