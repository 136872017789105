import { Redirect, Route, useLocation } from 'wouter'

import { NotFoundPage } from '@components/ErrorPage'
import { OAuthCallbackPage } from '@components/OAuthCallbackPage'
import { TourProvider } from '@components/ProductTour'
import { SnapshotLand } from '@components/SnapshotLand'
import { SleepOverlay } from '@routes/components/SleepOverlay'
import { findConfigForPathname, ROUTE_PATTERNS } from '@util/pathConfigs'

import * as Layouts from '../layouts'

import * as components from './components'
import { SettingsRouter } from './SettingsRouter'
import { StaffRouter } from './StaffRouter'
import { LayoutRoute, WallabyRouter } from './util'

/*
  Once we get to this router, we know the user has a current org
*/
const AppRouterInternal = () => {
  const [pathname] = useLocation()
  const currentRouteConfig = findConfigForPathname(pathname)

  return (
    <Layouts.AppWithHeader>
      {/* Header, Sidebar + body (script, rundown, new dashboard)
      Soon to be any authenticated route....
      */}
      <Route path={ROUTE_PATTERNS.scriptHistory} component={SnapshotLand} />

      <LayoutRoute component={Layouts.SidebarAndBody}>
        <Route
          path="/wayback/:scriptId"
          component={components.ArchivedScriptRoute}
        />
        <Route
          path={ROUTE_PATTERNS.rundown}
          component={components.EditorsRoute}
        />
        <Route
          path={ROUTE_PATTERNS.splitEditor}
          component={components.EditorsRoute}
        />
        <Route
          path={ROUTE_PATTERNS.scriptEditor}
          component={components.EditorsRoute}
        />
        <Route path="/" component={components.DashboardRoute} />
        <Route
          path="/folders/:folderId"
          component={components.DashboardRoute}
        />
        <Route path="/folders">
          <Redirect to="/" />
        </Route>
        <Route path={ROUTE_PATTERNS.scriptHistory} />
        {/* Delegate anything that starts with /settings here */}
        <Route path="/settings" nest component={SettingsRouter} />
        <Route path="/staff" nest component={StaffRouter} />
      </LayoutRoute>

      {/* oauth callback for slack integration */}
      <Route path="/oauth/callback" component={OAuthCallbackPage} />

      {/* Not found page */}
      {!currentRouteConfig && (
        <Layouts.CenteredScrollingBody>
          <NotFoundPage />
        </Layouts.CenteredScrollingBody>
      )}
    </Layouts.AppWithHeader>
  )
}

// TODO: we can move more into the app router & stop with the switching
export const AppRouter = () => (
  <TourProvider steps={[]}>
    <WallabyRouter>
      <SleepOverlay />
      <AppRouterInternal />
    </WallabyRouter>
  </TourProvider>
)
