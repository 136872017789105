import { Menu as MenuComponent } from '@mantine/core'

import classNames from './menu.module.scss'

export const Menu = MenuComponent.extend({
  defaultProps: {
    shadow: 'md',
    position: 'bottom-start',
  },
  classNames,
})
