import React from 'react'

import {
  ActionIcon,
  Button,
  createSafeContext,
  FloatingPosition,
  Group,
  Popover,
  PopoverProps,
  Stack,
  Title,
} from '@mantine/core'

import { FaIcon } from '@components/FaIcon'

import { MiniStepper } from './MiniStepper'

import styles from './AnnouncementPopover.module.scss'

export const [TourContextProvider, useTourContext] = createSafeContext<{
  currentStep: string | null
  jumpToStep: (index: number) => void
  nextStep: () => void
  startTour: () => void
  dismissTour: () => void
  isComplete: boolean
  steps: string[]
}>('useTourContext must be used within a TourProvider')

export function TourStep(props: {
  name: string
  children: React.ReactNode
  position?: FloatingPosition
  offset?: PopoverProps['offset']
  arrowOffset?: PopoverProps['arrowOffset']
}) {
  const { currentStep, isComplete } = useTourContext()
  const isActive = !isComplete && currentStep === props.name

  return (
    <Popover
      opened={isActive}
      width={350}
      shadow="xl"
      trapFocus
      withArrow
      arrowSize={15}
      offset={props.offset ?? -1}
      arrowOffset={props.arrowOffset}
      position={props.position}
    >
      {props.children}
    </Popover>
  )
}

TourStep.Target = Popover.Target

TourStep.Info = function TourStepInfo(props: {
  title: string
  children: React.ReactNode
}) {
  const { nextStep, steps, currentStep, jumpToStep, dismissTour } =
    useTourContext()
  const currentIndex = currentStep ? steps.indexOf(currentStep) : 0

  return (
    <Popover.Dropdown
      px={0}
      pt={0}
      className={styles.announcementPopover_dropdown}
    >
      <Stack className={styles.announcementPopover_header}>
        <Group justify="flex-end" pt={5} px={5}>
          <ActionIcon
            onClick={dismissTour}
            className={styles.announcementPopover_button}
          >
            <FaIcon c="dark.9" icon="fa-xmark" size="18" />
          </ActionIcon>
        </Group>
        <Title mt={-30} px={20} pb={15} order={3}>
          {props.title}
        </Title>
      </Stack>
      <Stack px={20} pt={21} pb={10}>
        {props.children}
        <Group justify="space-between" pb={10}>
          <MiniStepper
            active={currentIndex}
            onStepClick={jumpToStep}
            steps={steps}
          />
          <Button
            size="xs"
            className={styles.announcementPopover_button}
            onClick={nextStep}
            data-autofocus
          >
            {currentIndex === steps.length - 1 ? 'Finish' : 'Next'}
          </Button>
        </Group>
      </Stack>
    </Popover.Dropdown>
  )
}
