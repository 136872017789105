import { EditorState } from 'prosemirror-state'

import { NodeTypeKey, NodeTypeMap } from '@showrunner/codex'

import { BlockInfo, isNavLinkElement } from '@util/constants'

import { LEGIBLE_BLOCK_NAMES } from '../../prose-utils'

import { BlockInfoState } from './types'

const DOC_OFFSET = 1

export const verifyCharContd = (text: string, prev: string | null) => {
  const lower = text.trim().toLowerCase()
  const isVoiceover = /\(v\.o\.?\)/.test(lower)
  // would it be overkill to make this logic aware of the contdDisplayText?
  const isManualContd = lower.match(/\(cont.?d\)/)

  // a voiceover suppresses cont'ds
  // other parenthetical modifiers do not (and are stripped prior to comparing) 🙃
  const currentChar = isVoiceover
    ? lower
    : lower.replace(/\([a-zA-Z0-9-.')(\s]+\)$/, '').trim()

  const isContd = currentChar === prev?.toLowerCase() && !isManualContd
  return { currentChar, isContd }
}

export const extractBlockInfo = (state: EditorState): BlockInfoState => {
  const blockInfo: BlockInfoState = []

  let checkCharContd = false
  let prevChar: null | string = null

  state.doc.forEach((page, pageOffset) => {
    if (page.type.name !== NodeTypeMap.PAGE) return

    page.forEach((block, blockOffset) => {
      const {
        attrs: { elementNumber, id },
        nodeSize,
        textContent,
        type: { name: type },
      } = block

      // if id-maker plugin hasn't run yet, short-circuit
      if (!id) return

      const trimmedText = textContent.trim()
      const isBlank = trimmedText === ''
      const text =
        isBlank && isNavLinkElement(type)
          ? LEGIBLE_BLOCK_NAMES[type]
          : trimmedText
      const pos = blockOffset + pageOffset + DOC_OFFSET

      if (type === NodeTypeMap.SCENE_HEADING) {
        checkCharContd = true
        prevChar = null
      }

      const info: BlockInfo = {
        type: type as NodeTypeKey,
        text,
        pos,
        elementNumber,
        id,
        isBlank,
        nodeSize,
      }

      if (checkCharContd && type === NodeTypeMap.CHARACTER) {
        const { currentChar, isContd } = verifyCharContd(trimmedText, prevChar)
        info.isContd = isContd
        prevChar = currentChar
      }

      blockInfo.push(info)
    })
  })

  return blockInfo
}
