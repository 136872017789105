import { Menu } from '@mantine/core'

import { FancyMenuItem } from '@components/FancyMenuItem'
import { Toolbar } from '@components/Toolbar'
import { ButtonMode } from '@state'

import { type SideBySideContextOption } from '../types'

export const SideBySideViewMenu = ({
  linesOfContext,
  monochrome,
  setMonochrome,
  setLinesOfContext,
  mode,
}: {
  monochrome: boolean
  setMonochrome: Callback<[boolean]>
  linesOfContext: SideBySideContextOption
  setLinesOfContext: Callback<[SideBySideContextOption]>
  mode: ButtonMode
}) => (
  <Menu position="bottom-end">
    <Menu.Target>
      <Toolbar.Button mode={mode} icon="fa-sliders" label="Settings" dropdown />
    </Menu.Target>
    <Menu.Dropdown>
      <Menu.Label>SIDE-BY-SIDE VIEW SETTINGS</Menu.Label>
      <FancyMenuItem
        title="Changes only"
        onClick={() => setLinesOfContext('none')}
        selected={linesOfContext === 'none'}
      />
      <FancyMenuItem
        title="Changes with some context"
        onClick={() => setLinesOfContext('some')}
        selected={linesOfContext === 'some'}
      />
      <FancyMenuItem
        title="Full text"
        onClick={() => setLinesOfContext('all')}
        selected={linesOfContext === 'all'}
      />
      <Menu.Divider />

      <Menu.Item onClick={() => setMonochrome(!monochrome)}>
        {monochrome
          ? 'Show changes in color'
          : 'Show changes in black and white'}
      </Menu.Item>
    </Menu.Dropdown>
  </Menu>
)
