import { ActionIcon as ActionIconComponent } from '@mantine/core'

import classNames from './action-icon.module.css'

export const ActionIcon = ActionIconComponent.extend({
  defaultProps: {
    variant: 'subtle',
  },
  classNames,
})
