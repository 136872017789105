import React from 'react'

import { Overlay } from '@mantine/core'

import { showWhatsNewModal } from '@components/Modals'
import { useEffectOnlyOnce, useMst } from '@hooks'

import { TourContextProvider, TourStep, useTourContext } from './TourStep'

export { TourStep, useTourContext }

export function TourProvider(props: {
  steps: string[]
  children: React.ReactNode
}) {
  const { view } = useMst()
  const [currentStepIndex, setCurrentStepIndex] = React.useState(-1)
  const [isComplete, setIsComplete] = React.useState(false)

  const isActive = !isComplete && view.showWhatsNewModal

  const currentStep =
    currentStepIndex >= 0 ? props.steps[currentStepIndex] : null

  function nextStep() {
    if (currentStepIndex < props.steps.length - 1) {
      setCurrentStepIndex(currentStepIndex + 1)
    } else {
      dismissTour()
    }
  }

  function startTour() {
    setCurrentStepIndex(0)
  }

  function dismissTour() {
    setCurrentStepIndex(-1)
    setIsComplete(true)
  }

  function jumpToStep(stepIndex: number) {
    if (stepIndex <= props.steps.length) {
      setCurrentStepIndex(stepIndex)
    }
  }

  useEffectOnlyOnce(() => {
    // when the tour actually had steps, we passed through startTour here
    showWhatsNewModal(dismissTour)
  })

  return (
    <TourContextProvider
      value={{
        currentStep,
        jumpToStep,
        nextStep,
        isComplete,
        startTour,
        dismissTour,
        steps: props.steps,
      }}
    >
      {props.children}
      {isActive && <Overlay backgroundOpacity={0} />}
    </TourContextProvider>
  )
}
