import { Group, Menu, Text } from '@mantine/core'
import { observer } from 'mobx-react-lite'

import { insertDualDialogue } from '@choo-app/lib/editor/plugins/editor-keymap/commands'
import env from '@choo-app/lib/env'
import { CustomIcon } from '@components/CustomIcon'
import { FancyMenuItem } from '@components/FancyMenuItem'
import { showCustomizeStylesModal } from '@components/Modals/CustomizeStyles'
import { Toolbar } from '@components/Toolbar'
import { Keys, useShortcuts } from '@hooks'
import { IPopulatedPmEditor, useMst } from '@state'
import { ButtonMode, ILoadedScript } from '@state/types'
import { insertPageBreak, setEditorBlockType } from '@util'

import { menuData } from './display-data'
import { InlineElementMenuButton } from './InlineElementMenuButton'

export const ElementMenu = observer(function ElementMenu({
  script,
  observableEditor,
  mode,
}: {
  script: ILoadedScript
  observableEditor: IPopulatedPmEditor
  mode: ButtonMode
}) {
  const mst = useMst()

  const {
    editorView,
    editorState,
    focusEditor,
    selectionBlockType,
    selectionTouchesEndOfBlock,
    singleBlockTextSelection,
    rerender,
  } = observableEditor

  const { dispatch } = editorView

  const maxHeight = mst.view.dimensions.scriptScroller.height - 20

  const opened = script.elementMenuOpened

  const toggleMenu = () => {
    script.setElementMenuOpened(!opened)
    // if the user just closed the menu, refocus
    if (opened) focusEditor()
  }

  const menu = menuData[script.type]
  const curr = selectionBlockType
  // if a user copy/pastes a block type into a document that doesn't belong
  // we won't find it in the call below
  // this means we won't display an icon in the menu target, but this doesnt
  // they can't interact with the menu
  const meta = menu.find((i) => i.id === curr)
  const selectionSpansBlocks = !singleBlockTextSelection

  const tooltip = 'Change block type'
  const targetLabel = selectionSpansBlocks ? 'Multiple' : meta?.title

  // https://stackoverflow.com/questions/4215737/convert-array-to-object
  const elementShortcuts = menu.reduce(
    (a, v) => ({
      ...a,
      [v.id]: {
        keys: v.keys,
        action: () => updateBlockType(v.id),
      },
    }),
    {},
  )

  const { getItemProps } = useShortcuts({
    menu: {
      keys: [Keys.CMD, '\\'],
      action: toggleMenu,
    },
    pageBreak: {
      keys: [Keys.CMD, Keys.ENTER],
      action: injectPageBreak,
    },
    toggleMenu: {
      keys: [Keys.CMD, 'K'],
      action: toggleMenu,
    },
    ...elementShortcuts,
  })

  function updateBlockType(blockType: string) {
    setEditorBlockType(blockType)(editorState, dispatch)
    script.setElementMenuOpened(false)
    focusEditor()
    // ensure we take a fresh look at which block types have element numbers assigned
    rerender()
  }

  function injectPageBreak() {
    insertPageBreak(editorState, dispatch)
    focusEditor()
  }

  function injectDualDialogue() {
    mst.analytics.track('EDITOR_CREATE_DUAL_DIALOGUE')
    insertDualDialogue(editorState, dispatch)
    focusEditor()
  }

  // we display a custom icon when:
  // 1. the selection is within a single block
  // 2. a custom icon is actually present
  // 3. the menu in the toolbar isnt already open
  // 4. the user doesnt prefer to hide it
  const useCustomIcon = !selectionSpansBlocks && meta

  const showInlineButton =
    useCustomIcon && !opened && !mst.user.prefs.hideBlockMenu

  const isPageless = !!mst.user.prefs.pageless

  return (
    <>
      {showInlineButton && (
        <InlineElementMenuButton
          key={`pageless-${isPageless}`} // to recalculate pos on the fly
          onClick={toggleMenu}
          observableEditor={observableEditor}
          icon={meta.icon}
          isPageless={isPageless}
        />
      )}
      <Menu
        shadow="md"
        closeOnEscape={true}
        opened={opened}
        width={275}
        onClose={() => {
          script.setElementMenuOpened(false)
          focusEditor()
        }}
      >
        <Menu.Target>
          <Toolbar.Button
            customIcon={useCustomIcon && <CustomIcon icon={meta.icon} />}
            icon={!useCustomIcon ? 'fa-layer-group' : undefined}
            tooltip={tooltip}
            onClick={toggleMenu}
            label={targetLabel}
            mode={mode}
            dropdown
            fixedWidth={mode === 'normal' ? 120 : 40}
            disableTooltip={opened}
          />
        </Menu.Target>
        <Menu.Dropdown>
          <div style={{ maxHeight }}>
            {menu.map(({ id, icon, title }) => {
              const selected = id === meta?.id
              const props = getItemProps(id as 'menu' /* yeesh 🙃 */)
              return (
                <FancyMenuItem
                  key={id}
                  customIcon={
                    <CustomIcon
                      icon={icon}
                      color={selected ? 'white' : 'dark.4'}
                    />
                  }
                  title={title}
                  selected={selected}
                  shortcut={props.shortcut}
                  onClick={() => updateBlockType(id)}
                  hideShortcut={env.browser.safari}
                />
              )
            })}
            <Menu.Divider />
            <FancyMenuItem
              customIcon={<CustomIcon icon="icon-page-break" />}
              title="Insert page break"
              {...getItemProps('pageBreak')}
            />
            {script.isScreenplay && (
              <FancyMenuItem
                customIcon={<CustomIcon icon="icon-dual-dialogue" />}
                title="Insert dual dialogue"
                onClick={injectDualDialogue}
                disabled={!selectionTouchesEndOfBlock}
              />
            )}
            <Menu.Divider />
            <FancyMenuItem
              iconClass="fa fa-gear"
              title={
                <Group wrap="nowrap" gap={0}>
                  <Text lineClamp={3}>
                    Customize styles for&thinsp;
                    <Text span fw="bold">
                      {script.name}
                    </Text>
                  </Text>
                </Group>
              }
              onClick={() => showCustomizeStylesModal(script)}
            />
          </div>
        </Menu.Dropdown>
      </Menu>
    </>
  )
})
