import React, { ReactNode } from 'react'

import { CloseButton, Group, Stack } from '@mantine/core'

import { FaIcon } from '@components/FaIcon'

type PanelProps = React.PropsWithChildren<{
  header: ReactNode
  onClose(): void
}>

export function Panel(props: PanelProps) {
  return (
    <Stack
      component="aside"
      gap={0}
      w={350}
      h="100%"
      style={{
        borderWidth: '0 0 0 1px',
        borderStyle: 'solid',
        borderColor: 'var(--mantine-color-gray-3)',
      }}
    >
      <Group
        justify="space-between"
        ps={10}
        pe={5}
        py={5}
        gap={4}
        style={{
          borderWidth: '0 0 1px 0',
          borderStyle: 'solid',
          borderColor: 'var(--mantine-color-gray-3)',
        }}
      >
        {props.header}{' '}
        <CloseButton
          icon={<FaIcon icon="fa-xmark" size="16" />}
          onClick={props.onClose}
        />
      </Group>
      {props.children}
    </Stack>
  )
}
