// These are all the pathname patterns we recognize in our app
import { parse } from 'regexparam'

// these are the ones that choo is involved with (even if just for routing logic)
const scriptEditor = '/scripts/:scriptId'
const splitEditor = '/rundowns/:rundownId/scripts/:scriptId'
const scriptHistory = '/scripts/:scriptId/history'

// these are ones that choo needs know nothing about
const root = '/'
const folder = '/folders/:folderId'
const staff = '/staff/*?'
const rundown = '/rundowns/:rundownId'
const archivedScript = '/wayback/:id'
const settings = '/settings/*?'
const oauthCallback = '/oauth/callback'
const login = '/login'
const logout = '/logout'
const createAccount = '/create-account'
const forgotPassword = '/forgot-password'
const secondFactor = '/second-factor/:factor/:action'
// temporary route to help us lazily migrate legacy users to stytch
const updatePassword = '/update-password'

const inkPlayer = '/inkplayer'

// signup is an oddball-- allows authed/unauthed requests & redirects them if not authed.
// It's slated for retirement once we stop using invite codes.
const signup = '/signup'

// allows authed requests with or without orgs
const createWorkspace = '/create-workspace'

export const ROUTE_PATTERNS = {
  root,
  scriptEditor,
  splitEditor,
  scriptHistory,
  signup,
  createWorkspace,
  folder,
  staff,
  rundown,
  archivedScript,
  settings,
  oauthCallback,
  login,
  logout,
  createAccount,
  forgotPassword,
  updatePassword,
  secondFactor,
  inkPlayer,
} as const

export type RoutePattern = keyof typeof ROUTE_PATTERNS

interface Matcher {
  pattern: RegExp
  keys: string[]
}

export type RoutePathConfig = {
  pattern: string
  matcher: Matcher
  requiresAuth: boolean
  requiresOrg: boolean
  requiresStaff: boolean
}

const staffRoute = (pattern: string): RoutePathConfig => ({
  pattern,
  matcher: parse(pattern),
  requiresAuth: true,
  requiresStaff: true,
  // for now this is the case for staff routes because the header blows
  // up without an org.
  requiresOrg: true,
})

const workspaceRoute = (pattern: string): RoutePathConfig => ({
  pattern,
  matcher: parse(pattern),
  requiresAuth: true,
  requiresStaff: false,
  requiresOrg: true,
})

const preauthRoute = (pattern: string): RoutePathConfig => ({
  pattern,
  matcher: parse(pattern),
  requiresAuth: false,
  requiresStaff: false,
  requiresOrg: false,
})

// This expresses the relationship between a supplied pathname
// and a route configuration.
const configs: Record<RoutePattern, RoutePathConfig> = {
  // commonplace routes-- you're in an org
  scriptEditor: workspaceRoute(scriptEditor),
  splitEditor: workspaceRoute(splitEditor),
  root: workspaceRoute(root),
  folder: workspaceRoute(folder),
  rundown: workspaceRoute(rundown),
  archivedScript: workspaceRoute(archivedScript),
  settings: workspaceRoute(settings),
  oauthCallback: workspaceRoute(oauthCallback),
  scriptHistory: workspaceRoute(scriptHistory),
  inkPlayer: preauthRoute(inkPlayer),

  staff: staffRoute(staff),

  // These do not require a user to belong to an org
  createWorkspace: {
    requiresAuth: true,
    requiresOrg: false,
    requiresStaff: false,
    pattern: createWorkspace,
    matcher: parse(createWorkspace),
  },
  signup: {
    requiresAuth: false,
    requiresOrg: false,
    requiresStaff: false,
    pattern: signup,
    matcher: parse(signup),
  },
  secondFactor: {
    ...preauthRoute(secondFactor),
    requiresAuth: true,
  },

  // pre-auth routes
  login: preauthRoute(login),
  logout: preauthRoute(logout),
  createAccount: preauthRoute(createAccount),
  forgotPassword: preauthRoute(forgotPassword),
  updatePassword: preauthRoute(updatePassword),
}

export const findConfigForPathname = (
  pathname: string,
): RoutePathConfig | undefined => {
  return Object.values<RoutePathConfig>(configs).find((config) =>
    config.matcher.pattern.test(pathname),
  )
}

// This is figuring out whether a url is for a configured pattern and if so
// what its parameters are. For example, if the url is /scripts/abc/snapshots/def
// this will return { scriptId: 'abc', snapshotId: 'def' }. But if it doesn't match
// any configured patterns, it will return null.
//
// NOTE: this is something we're doing that wouter does internally but we need to do it in
// mst and it's only exposed there as a hook. Once we get more of the routing out of mst, we
// can move this into a route where it really belongs (e.g. the loading route on app launch)
export const extractParameters = (pathname: string): StringMap | null => {
  const config = findConfigForPathname(pathname)

  if (!config) return null

  const matches = config.matcher.pattern.exec(pathname)
  if (matches === null) return null

  return config.matcher.keys.reduce<StringMap>((params, key, i) => {
    // matches[0] is the full match, params start at index 1
    params[key] = matches[i + 1]
    return params
  }, {})
}

export const extractResourceParameter = (
  pathname: string,
): {
  resourceType: ListingType
  resourceId: string | number
} | null => {
  const params = extractParameters(pathname)
  if (params?.rundownId) {
    return {
      resourceType: 'rundown',
      resourceId: parseInt(params.rundownId),
    }
  }
  if (params?.scriptId) {
    return {
      resourceType: 'script',
      resourceId: params.scriptId,
    }
  }
  if (params?.folderId) {
    return {
      resourceType: 'folder',
      resourceId: params.folderId,
    }
  }
  return null
}

const validRedirectAfterLogin = (pathname: string): boolean => {
  const config = findConfigForPathname(pathname)
  if (!config || !config.requiresAuth) {
    return false
  }
  if ([logout, oauthCallback].includes(config.pattern)) {
    return false
  }
  return true
}

export const buildLoginRedirect = ({
  pathname,
  search,
}: {
  pathname: string
  search: string
}) => {
  const canReturnTo = validRedirectAfterLogin(pathname)
  const hasInformation = pathname !== ROUTE_PATTERNS.root || search.length > 0

  if (canReturnTo && hasInformation) {
    return `${ROUTE_PATTERNS.login}?returnTo=${pathname}${search}`
  }
  return ROUTE_PATTERNS.login
}
