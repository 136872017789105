import {
  StytchAPIError,
  StytchHeadlessClient,
  User as StytchUser,
} from '@stytch/vanilla-js/headless'
import { z } from 'zod'

import { schemas, ZInfer } from '@showrunner/scrapi'

import { DatadogClient } from '@util/datadog'

const ddlog = DatadogClient.getInstance()

// now that we make a handful of calls directly to stytch,
// there is no need to duplicate them in our own contracts
export const knownStytchErrorCodes = z.enum([
  'BREACHED_PASSWORD',
  'PHONE_NUMBER_NOT_FOUND',
  'INVALID_PHONE_NUMBER',
  'DUPLICATE_PHONE_NUMBER',
  'OTP_CODE_NOT_FOUND',
  'UNABLE_TO_AUTH_OTP_CODE',
  'INVALID_PHONE_NUMBER_COUNTRY_CODE',
  'TOO_MANY_UNVERIFIED_FACTORS',
  'USER_UNAUTHENTICATED',
  // Some Stytch error codes map to known API error codes, since we re-throw
  // them from api and nido
  schemas.AuthErrorCode.Enum.INVALID_CREDENTIALS,
  schemas.AuthErrorCode.Enum.RESET_PASSWORD,
  schemas.AuthErrorCode.Enum.OTP_EXPIRED_OR_USED,
  schemas.AuthErrorCode.Enum.TOO_MANY_REQUESTS,
])

export type KnownStytchErrorCode = ZInfer<typeof knownStytchErrorCodes>

export function userFacingStytchError(code: KnownStytchErrorCode): string {
  switch (code) {
    case 'INVALID_CREDENTIALS':
      return 'Invalid credentials'
    case 'BREACHED_PASSWORD':
    case 'RESET_PASSWORD':
      return 'Please choose a different password'
    case 'PHONE_NUMBER_NOT_FOUND':
      return 'Please enter your phone number again'
    case 'INVALID_PHONE_NUMBER':
      return 'Please provide a valid phone number with country code'
    case 'DUPLICATE_PHONE_NUMBER':
      return 'Please choose a different phone number'
    case 'OTP_CODE_NOT_FOUND':
    case 'UNABLE_TO_AUTH_OTP_CODE':
      return 'Incorrect verification code provided'
    case 'OTP_EXPIRED_OR_USED':
      return 'The verification code has expired'
    case 'INVALID_PHONE_NUMBER_COUNTRY_CODE':
      return 'This country code is not supported over SMS'
    case 'TOO_MANY_UNVERIFIED_FACTORS':
      return 'Please try again in five minutes'
    case 'TOO_MANY_REQUESTS':
      return 'Too many attempts have been made to authenticate. Please either wait and try again or reach out to support@scripto.live for assistance.'
    case 'USER_UNAUTHENTICATED':
      return 'This session has timed out — please refresh the page'
  }
}

type ServerAuthErrorCode = ZInfer<typeof schemas.AuthErrorCode>

export type AuthOrStytchErrorCode = ServerAuthErrorCode | KnownStytchErrorCode

export type LoginResult =
  | { success: true }
  | { success: false; code: AuthOrStytchErrorCode }

type SuccessResponse<T> = T & { success: true }

type StytchErrorResponse = {
  success: false
  code: KnownStytchErrorCode | 'UNEXPECTED_ERROR'
  message: string
}

type StytchAuthResponse<T = unknown> = SuccessResponse<T> | StytchErrorResponse

export type StytchAuthenticateResponse = StytchAuthResponse<{
  sessionToken: string
}>

export type StytchSendOtpResponse = StytchAuthResponse<{
  methodId: string
}>

export type StytchGenericResponse = StytchAuthResponse

// when we call some stytch sdk methods, we get back a raw string. Some
// we don't know how to handle, others we deal with explicitly
const stytchErrorMap: { [key: string]: KnownStytchErrorCode } = {
  unauthorized_credentials: 'INVALID_CREDENTIALS',
  // in the UI we treat a missing email the same as an invalid password
  email_not_found: 'INVALID_CREDENTIALS',
  // this is returned when passwords get compromised
  reset_password: 'RESET_PASSWORD',
  phone_number_not_found: 'PHONE_NUMBER_NOT_FOUND',
  invalid_phone_number: 'INVALID_PHONE_NUMBER',
  duplicate_phone_number: 'DUPLICATE_PHONE_NUMBER',
  otp_code_not_found: 'OTP_CODE_NOT_FOUND',
  unable_to_auth_otp_code: 'UNABLE_TO_AUTH_OTP_CODE',
  too_many_unverified_factors: 'TOO_MANY_UNVERIFIED_FACTORS',
  invalid_phone_number_country_code: 'INVALID_PHONE_NUMBER_COUNTRY_CODE',
  too_many_requests: 'TOO_MANY_REQUESTS',
  user_unauthenticated: 'USER_UNAUTHENTICATED',
}

export interface IStytchClient {
  authenticateWithPassword: (args: {
    email: string
    password: string
  }) => Promise<StytchAuthenticateResponse>

  authenticateWithOtp: (
    otp: string,
    passCodeId: string,
  ) => Promise<StytchAuthenticateResponse>

  deletePhoneNumber: (phoneNumber: string) => Promise<StytchGenericResponse>

  getUserSync: () => StytchUser | null

  sendOtp: (
    phoneNumber: string,
    method: 'sms' | 'whatsapp',
  ) => Promise<StytchSendOtpResponse>

  resetPassword: (newPassword: string) => Promise<StytchGenericResponse>
}

export class DevStytchClient implements IStytchClient {
  authenticateWithPassword = async ({
    email,
    password,
  }: {
    email: string
    password: string
  }): Promise<StytchAuthenticateResponse> => {
    if (password !== 'I am strong') {
      return {
        success: false,
        code: 'INVALID_CREDENTIALS',
        message: '"I am strong"',
      }
    }
    return Promise.resolve({
      success: true,
      sessionToken: email,
    })
  }

  authenticateWithOtp = async (
    otp: string,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    passCodeId: string,
  ): Promise<StytchAuthenticateResponse> => {
    if (otp !== '000000') {
      return {
        success: false,
        code: 'INVALID_CREDENTIALS',
        message: '"000000"',
      }
    }
    return Promise.resolve({
      success: true,
      sessionToken: otp,
    })
  }

  sendOtp = async (phoneNumber: string, method: 'sms' | 'whatsapp') => {
    return Promise.resolve({
      success: true,
      methodId: method + phoneNumber + '-fake',
    } as { success: true; methodId: string })
  }

  deletePhoneNumber = async () => {
    return Promise.resolve({ success: true } as { success: true })
  }

  resetPassword = async () => {
    return Promise.resolve({ success: true } as { success: true })
  }

  getUserSync = () => null
}

// wrap the stytch client so we can return expected failures in a nicer way
export class StytchClient implements IStytchClient {
  readonly _rawClient: StytchHeadlessClient
  constructor(publicToken: string) {
    this._rawClient = new StytchHeadlessClient(publicToken)
  }

  private _processError = (e: unknown): StytchErrorResponse => {
    if (e instanceof StytchAPIError) {
      const code = stytchErrorMap[e.error_type]
      const knownStytchErrorCode = knownStytchErrorCodes.safeParse(code)

      if (knownStytchErrorCode.success) {
        return {
          success: false,
          code: knownStytchErrorCode.data,
          message: userFacingStytchError(knownStytchErrorCode.data),
        }
      }
    }
    ddlog.error('unexpected stytch error', {}, e)
    return {
      success: false,
      code: 'UNEXPECTED_ERROR',
      message: 'An unknown error occurred',
    }
  }

  authenticateWithPassword = async ({
    email,
    password,
  }: {
    email: string
    password: string
  }): Promise<StytchAuthenticateResponse> => {
    try {
      const stychResponse = await this._rawClient.passwords.authenticate({
        email,
        password,
        session_duration_minutes: 10,
      })
      return {
        success: true,
        sessionToken: stychResponse.session_token,
      }
    } catch (e) {
      return this._processError(e)
    }
  }

  authenticateWithOtp = async (
    otp: string,
    passCodeId: string,
  ): Promise<StytchAuthenticateResponse> => {
    try {
      const stychResponse = await this._rawClient.otps.authenticate(
        otp,
        passCodeId,
        { session_duration_minutes: 5 },
      )
      return {
        success: true,
        sessionToken: stychResponse.session_token,
      }
    } catch (e) {
      return this._processError(e)
    }
  }

  sendOtp = async (
    phoneNumber: string,
    method: 'sms' | 'whatsapp',
  ): Promise<StytchSendOtpResponse> => {
    try {
      const { method_id } = await this._rawClient.otps[method].send(
        phoneNumber,
        { expiration_minutes: 5 },
      )
      return {
        success: true,
        methodId: method_id,
      }
    } catch (e) {
      return this._processError(e)
    }
  }

  deletePhoneNumber = async (phoneId: string) => {
    try {
      await this._rawClient.user.deletePhoneNumber(phoneId)
      return { success: true } as { success: true }
    } catch (e) {
      return this._processError(e)
    }
  }

  resetPassword = async (password: string) => {
    try {
      await this._rawClient.passwords.resetBySession({
        password,
        session_duration_minutes: 5,
      })
      return { success: true } as { success: true }
    } catch (e) {
      return this._processError(e)
    }
  }

  getUserSync = () => this._rawClient.user.getSync()
}

export const getStytchClient = (token: string | null) => {
  if (!token) {
    return new DevStytchClient()
  }
  return new StytchClient(token)
}
