import { Code, Group, Space, Stack, Table, Text, Title } from '@mantine/core'
import { useQuery } from '@tanstack/react-query'
import { format } from 'date-fns'
import { observer } from 'mobx-react-lite'

import { schemas, ZInfer } from '@showrunner/scrapi'

import { Loader } from '@components/Loader'
import { NavAnchor } from '@components/NavAnchor'
import { useMst } from '@state'

import { Breadcrumbs } from '../Breadcrumbs'
import { displayRole, staleTime } from '../helpers'

import { StaffCheckbox } from './StaffCheckbox'

const UserDetailInternal = ({
  user,
}: {
  user: ZInfer<typeof schemas.GetUserByStaffResponse>
}) => (
  <Stack>
    <Breadcrumbs crumbs={[{ text: 'Users', url: '/users' }]} />
    <Group>
      <Text>
        email: <Code>{user.email}</Code>
      </Text>
      <StaffCheckbox user={user} />
    </Group>
    <Text>
      created: <Code>{format(user.createdAt, "M/d/yyyy 'at' h:mmaaa")}</Code>
    </Text>
    <Text>
      last updated:&nbsp;
      <Code>{format(user.updatedAt, "M/d/yyyy 'at' h:mmaaa")}</Code>
    </Text>
    <Text>
      id: <Code fz="12">{user.id}</Code>
    </Text>
    <Text>
      stytchId: <Code fz="12">{user.stytchId}</Code>
    </Text>
    <Text>
      intercomHash: <Code fz="12">{user.intercomHash}</Code>
    </Text>
    <Space h={10} />
    <Title order={3}>Workspaces ({user.workspaces.length})</Title>
    <Table>
      <Table.Thead>
        <Table.Tr>
          <Table.Th>Name</Table.Th>
          <Table.Th>Role</Table.Th>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {user.workspaces.map((w) => (
          <Table.Tr key={w.id}>
            <Table.Td>
              <NavAnchor href={`/workspaces/${w.id}`}>{w.name}</NavAnchor>
            </Table.Td>
            <Table.Td>{displayRole(w.ownerId === user.id, w.isAdmin)}</Table.Td>
          </Table.Tr>
        ))}
      </Table.Tbody>
    </Table>
  </Stack>
)

export const UserDetail = observer(function OrgDetail({
  params,
}: {
  params: { uuid: string }
}) {
  const mst = useMst()
  const userId = params.uuid

  const userQuery = async () =>
    await mst.scrapi.staff.getUser({ params: { id: userId } })

  const { isLoading, isError, data } = useQuery({
    queryKey: ['staff_user_info', { userId }],
    queryFn: userQuery,
    staleTime,
  })

  if (isLoading) return <Loader fullScreen={true} />

  if (isError || !data || data.status !== 200) {
    return <Title>Unexpected error</Title>
  }

  return <UserDetailInternal user={data.body} />
})
