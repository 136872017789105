import {
  Anchor,
  Card,
  Center,
  Container,
  SimpleGrid,
  Space,
  Text,
  Title,
} from '@mantine/core'

import { NavAnchor } from '@components/NavAnchor'
import { LogoutForm } from '@routes/components'
import { useMst } from '@state'
import { ROUTE_PATTERNS } from '@util'

import logo from '../../assets/images/logo-light.svg'

import styles from './NoShow.module.scss'

export const NoShow = () => (
  <Container size="xl" className={styles.darkpage}>
    <Space h="lg" />
    <Container size="sm">
      <Center>
        <img className={styles.darkpage_logo} src={logo} alt="Scripto Logo" />
      </Center>
      <Title order={1}>You&apos;re not a member of a workspace</Title>
      <Text size="lg">
        You have an existing Scripto account (
        <strong>{useMst().user.email}</strong>
        ), but you need to belong to at least one workspace to use Scripto.
      </Text>
      <Space h="xl" />
      <SimpleGrid cols={{ base: 1, sm: 2 }} spacing="lg">
        <Card shadow="lg" className={styles.darkpage_card}>
          <Title order={2}>Trying to join an existing workspace?</Title>
          <Space h="lg" />
          <Text size="lg">
            Ask an admin from the workspace you want to join to send you an
            invite.
          </Text>
          <Space h="sm" />
          <Text size="lg">
            If you think you&apos;ve already been sent an invite, check your
            email, including your spam folder.
          </Text>
        </Card>
        <Card shadow="lg" className={styles.darkpage_card}>
          <Title order={2}>Trying to make a brand new workspace?</Title>

          <Space h="lg" />
          <Text size="lg">
            You can&nbsp;
            <NavAnchor
              inline
              className={styles.darkpage_link}
              href={ROUTE_PATTERNS.createWorkspace}
            >
              create a new workspace
            </NavAnchor>
            &nbsp;from scratch!
          </Text>
        </Card>
      </SimpleGrid>
      <Space h="xl" />
      <Center>
        <LogoutForm>
          <Anchor
            component="button"
            type="submit"
            inline
            ta="center"
            className={styles.darkpage_link}
          >
            Log Out
          </Anchor>
        </LogoutForm>
      </Center>
    </Container>
  </Container>
)
