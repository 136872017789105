/*
  This uses react-dnd to create a wrapper component for our RundownGrid. It is responsible
  for determining what row a script is being dragged over (or if we're above/below all nodes)
  and doing the following:

  1. Use the AgGridHelper to toggle highlights above/below rows as the script drag position changes
  2. When a script is dropped, using the rundown operations to trigger the insert script in the
     proper location
*/
import React from 'react'

import { RowHighlightPosition } from '@ag-grid-community/core'
import { CSSObject, Global } from '@emotion/react'
import { useDrop } from 'react-dnd'

import { showError } from '@components/Modals'
import { useMst } from '@state'
import { IRundown } from '@state/types'

import { useRundownOperations } from './useRundownOperations'

import styles from './DropTargetWrapper.module.scss'

const getRowIdFromElement = (elt: Element): number | null => {
  const idStr = elt.getAttribute('row-id')
  const idNum = idStr ? parseInt(idStr) : NaN
  return isNaN(idNum) ? null : idNum
}

const buildDropTargetStyle = (
  targetRowId: number | undefined,
  position: RowHighlightPosition,
): { [key: string]: CSSObject } | undefined => {
  if (targetRowId) {
    const rowSelector = `.ag-row[row-id="${targetRowId}"]`
    const pseudoSelector =
      position === RowHighlightPosition.Above ? '::before' : '::after'

    return {
      [rowSelector + pseudoSelector]: {
        borderTopWidth: 2,
      },
    }
  }
}

export const DropTargetWrapper = ({
  rundown,
  children,
}: {
  rundown: IRundown
  children: React.ReactNode
}) => {
  const { scriptMap } = useMst()
  const operations = useRundownOperations(rundown)
  // when we drag over the grid, query the native dom to get row elements
  // and store them in state. We'll need to inspect their coords to know if
  // we're dragging over a row or not
  const [targetRowId, setTargetRowId] = React.useState<number | undefined>()
  const [targetPosition, setTargetPosition] =
    React.useState<RowHighlightPosition>(RowHighlightPosition.Above)

  // Note: dragY is managed purely to get the useDrop function to re-run. That function is
  // memoized and we need it to re-run whenever this value changes.
  const [dragY, setDragY] = React.useState<number | undefined>()

  const handleDrop = ({ id: scriptId }: { id: string }) => {
    const name = scriptMap.get(scriptId)?.name ?? 'The script'
    if (rundown.findScriptRow(scriptId)) {
      showError({
        message: `"${name}" has already been added to this rundown. You cannot add it again.`,
      })
      return
    }

    // if we drop a script on the grid, see if it is over a node and if
    // so, use the sequence of that node to determine the drop position. If not,
    // use the last visible node & we will append
    const targetRow =
      typeof targetRowId === 'number'
        ? rundown.getRow(targetRowId)
        : rundown.lastRow

    // if we have a target note, we want to use its sequence. If we don't that means we've
    // got no nodes, so we don't really care what sequence we put it at.
    const targetNodeSequence = targetRow ? targetRow.sequence : 1
    // put it below the target node if it was dragged over the lower half of the node
    const sequence =
      targetPosition === RowHighlightPosition.Below
        ? targetNodeSequence + 1
        : targetNodeSequence

    operations.insertScriptRow({ scriptId, sequence, name })
  }

  const handleDragYChange = (y: number | undefined) => {
    setDragY(y)
    // iterate through all the dom nodes that the drag might be over and
    // extract the row ID from its ag-grid provided row-id attribute
    if (typeof y === 'number') {
      const rowElements = Array.from(
        document.querySelectorAll('.ag-row[row-id]'),
      )
      for (let i = 0; i < rowElements.length; i++) {
        const elt = rowElements[i]
        const { top, height } = elt.getBoundingClientRect()
        const rowId = getRowIdFromElement(elt)
        // this if clause means we've found the row. We just want to find whether
        // it's on the upper or lower half
        if (typeof rowId === 'number' && y > top && y <= top + height) {
          const position =
            y > top + height / 2
              ? RowHighlightPosition.Below
              : RowHighlightPosition.Above
          setTargetRowId(rowId)
          setTargetPosition(position)
          return
        }
      } // end of for loop

      // We didn't find a matching row, but if we have rows we want to mark the top or the bottom
      // (this happens if you drag over the header or over the blank bottom area)
      if (rowElements.length > 0) {
        const insertBelow = y > rowElements[0].getBoundingClientRect()?.y
        const position = insertBelow
          ? RowHighlightPosition.Below
          : RowHighlightPosition.Above

        const rowId = insertBelow ? rundown.lastRow?.id : rundown.firstRow?.id

        if (typeof rowId === 'number') {
          setTargetRowId(rowId)
          setTargetPosition(position)
        }
      }
    }
  }

  const dropTargetRef = React.useRef<HTMLDivElement>(null)

  // ag-grid handles drag/drop WITHIN the grid. This useDrop is to allow
  // non-grid elements to be dropped onto the grid (e.g. to create script rows or to
  // associate scripts/elements with existing rows)
  const [{ isOver }, connectDropTarget] = useDrop<
    { id: string },
    unknown,
    {
      isOver: boolean
    }
  >(
    () => ({
      accept: 'script',
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
      }),
      hover: (item, monitor) => {
        handleDragYChange(monitor.getClientOffset()?.y)
      },
      drop: handleDrop,
    }),
    [dragY],
  )

  connectDropTarget(dropTargetRef)

  React.useEffect(() => {
    // reset the target if we're not dragging over the rundown
    if (!isOver) {
      setTargetRowId(undefined)
      setTargetPosition(RowHighlightPosition.Above)
    }
  }, [isOver])

  return (
    <div className={styles.dropTargetWrapper} ref={dropTargetRef}>
      <Global styles={buildDropTargetStyle(targetRowId, targetPosition)} />
      {children}
    </div>
  )
}
