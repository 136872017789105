import * as Plugins from './plugins'
import { EditorContext, PluginConfig, PluginFactory } from './plugins/types'

const LIVE_EDITOR_PLUGINS: Array<PluginConfig> = [
  Plugins.configData, // must be loaded first
  Plugins.blockInfo,
  Plugins.docInfo,
  Plugins.collabUpdate,
  Plugins.characterAutocomplete,
  Plugins.characterContds,
  Plugins.removeStructuralPagination,
  Plugins.removeFormatInfo,
  Plugins.inputRules,
  Plugins.inlineManualPageBreaks,
  Plugins.inlinePageBreaks,
  Plugins.inkInputRules,
  Plugins.editorKeymap,
  Plugins.inkKeymap,
  Plugins.baseKeymap,
  Plugins.ensureNewline,
  Plugins.remoteCollaborators,
  Plugins.idMaker,
  Plugins.indentationStyles,
  Plugins.historyPlugin,
  Plugins.comments,
  Plugins.elementNumbers,
  Plugins.syntaxHighlighting,
  Plugins.decorateSelection,
  Plugins.recordDomPositionsPlugin,
]

const storageKey = (pluginKey: string) => `omit-plugin:${pluginKey}`
const enablePlugin = (context: EditorContext, pluginConfig: PluginConfig) => {
  if (window.localStorage.getItem(storageKey(pluginConfig.key))) {
    return false
  }

  if (pluginConfig.checkEnabled && !pluginConfig.checkEnabled(context)) {
    return false
  }
  return true
}

export const getLiveEditorPlugins = (
  editorContext: EditorContext,
): PluginFactory[] => {
  return LIVE_EDITOR_PLUGINS.filter((p) => enablePlugin(editorContext, p)).map(
    (p) => p.plugin,
  )
}
