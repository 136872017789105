import { Button, Card, Grid, Stack, Text } from '@mantine/core'

import { showRetireWorkspaceModal } from '@components/Modals'
import { NavAnchor } from '@components/NavAnchor'
import { IOrg, useMst } from '@state'
import { pluralize, ROUTE_PATTERNS } from '@util'

import { useInviteData } from '../useInviteData'

const getLinkText = ({
  memberCount,
  openInviteCount,
}: {
  memberCount: number
  openInviteCount: number
}): string | null => {
  const messages: string[] = []
  if (memberCount > 1) messages.push('remove all collaborators')
  if (openInviteCount > 0) messages.push('revoke all pending invites')
  return messages.join(' and ')
}

export const StatusInfo = ({ org }: { org: IOrg }) => {
  const { user, scrapi } = useMst()
  const { openInviteCount } = useInviteData(org)

  const isOwner = user.isCurrentOrgOwner
  const memberCount = org.members.length

  const noOpenInvites = openInviteCount === 0
  const noCollaborators = memberCount < 2
  const canRetire = isOwner && noCollaborators && noOpenInvites

  const finePrint = canRetire ? (
    'You will not be able to access your workspace after retiring it'
  ) : (
    <>
      You must&thinsp;
      <NavAnchor href="/org/members">
        {getLinkText({ memberCount, openInviteCount })}
      </NavAnchor>
      &thinsp;before you can retire your workspace
    </>
  )

  const onConfirm = async () => {
    const { status, body } = await scrapi.workspaces.retire({
      params: { id: org.id },
      body: {},
    })
    if (status === 200) {
      user.trackEvent('SHOW_RETIRED')
      window.location.replace(ROUTE_PATTERNS.root)
      return Promise.resolve({ success: true })
    }
    const message = status === 420 ? body.message : 'Failed to retire workspace'
    return Promise.resolve({ success: false, message })
  }

  return (
    <Stack gap="sm">
      <Text fw="bold">Workspace status</Text>
      <Card>
        <Grid align="center">
          <Grid.Col span={6}>
            <Text>
              <Text span fw="bold">
                {org.name}
              </Text>
              {` is an active workspace with ${memberCount} ${pluralize(
                memberCount,
                'member',
              )}`}
            </Text>
          </Grid.Col>
          <Grid.Col span={6}>
            {user.isCurrentOrgOwner && (
              <Stack gap={5} align="flex-end">
                <Button
                  size="sm"
                  variant="danger"
                  disabled={!canRetire}
                  onClick={() =>
                    showRetireWorkspaceModal({
                      workspaceName: org.name,
                      onConfirm,
                      hasPrivate: org.hasPrivateScriptsEnabled,
                    })
                  }
                >
                  Retire workspace
                </Button>
                <Text size="sm" c="dimmed" ta="right">
                  {finePrint}
                </Text>
              </Stack>
            )}
          </Grid.Col>
        </Grid>
      </Card>
    </Stack>
  )
}
