import React from 'react'

import { Anchor, Stack, Text } from '@mantine/core'
import { Link, LinkProps } from 'wouter'

import { useMst } from '@state'

import { Breadcrumbs } from '../Breadcrumbs'

const StaffLink: React.FunctionComponent<LinkProps & { text: string }> = ({
  text,
  children,
  ...linkProps
}) => (
  <Text>
    <Link asChild {...linkProps}>
      <Anchor>{text}</Anchor>
    </Link>{' '}
    - {children}
  </Text>
)

export const LandingPage = () => {
  const { environment } = useMst()
  const { API_URL, NIDO_URL } = environment.config

  const isHostedServer = API_URL.startsWith('https')
  const isHostedClient = window.location.protocol === 'https:'
  const canShowBull = isHostedServer === isHostedClient
  const bullBoardOrigin = isHostedServer ? window.location.origin : NIDO_URL

  return (
    <Stack gap="lg">
      <Breadcrumbs />

      <StaffLink href="/flags" text="Beta Flags">
        Add and remove all shows from individual flags
      </StaffLink>

      <StaffLink href="/templates" text="Template Workspaces">
        Manage the templates used to create new workspaces
      </StaffLink>

      <StaffLink href="/invites" text="Invites">
        Send a new workspace creation invite, or search for previous invites
      </StaffLink>

      <StaffLink to="/rundowns" text="Rundowns">
        Manage rundown schemas
      </StaffLink>

      <StaffLink to="/formats" text="Script Formats">
        View and create script formats
      </StaffLink>

      <StaffLink to="/users" text="Users">
        List user info for support & debugging
      </StaffLink>

      <StaffLink to="/workspaces" text="Workspaces">
        Find a workspace by name, tier, status or flag
      </StaffLink>

      <StaffLink to="/clone" text="Clone workspace">
        Create a new workspace by cloning an existing one
      </StaffLink>

      {canShowBull ? (
        <StaffLink to={`~${bullBoardOrigin}/staff/bullmq`} text="Bull MQ">
          {'🐂 '.repeat(9)}
        </StaffLink>
      ) : (
        <Text>Bull MQ - 😿 (no joy for local client with remote server)</Text>
      )}
    </Stack>
  )
}
