import { inject } from 'regexparam'

import { ROUTE_PATTERNS } from '@util'

import { SnapshotViewType } from './types'

interface QueryParams {
  view?: SnapshotViewType
  snap1?: string
  snap2?: string
}

export function snapshotsPath(scriptId: string, params: QueryParams) {
  const path = inject(ROUTE_PATTERNS.scriptHistory, { scriptId })
  const searchParams = new URLSearchParams()

  if (params.view) searchParams.set('view', params.view)

  if (params.snap1) {
    searchParams.set('snap1', params.snap1)
  } else {
    searchParams.set('redirect', 'most-recent')
  }

  if (params.snap2) searchParams.set('snap2', params.snap2)

  return path + '?' + searchParams.toString()
}
