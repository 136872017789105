import { Group, Menu, Text, UnstyledButton } from '@mantine/core'
import { observer } from 'mobx-react-lite'
import { Link } from 'wouter'

import { Avatar } from '@components/Avatar'
import { FaIcon } from '@components/FaIcon'
import { LogoutForm } from '@routes/components'
import { useMst } from '@state'

export const UserMenuBase = ({
  isOnline,
  userName,
  avatarUrl,
  isStaff,
}: {
  userName: string
  isStaff: boolean
  avatarUrl?: string
  isOnline: boolean
}) => {
  return (
    <Menu shadow="xl" withinPortal position="right-end">
      <Menu.Target>
        <UnstyledButton>
          <Group gap={5} wrap="nowrap">
            <Avatar
              statusIndicator={isOnline ? 'online' : 'offline'}
              avatarUrl={avatarUrl}
            />
          </Group>
        </UnstyledButton>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Label>
          <Text size="16" fw="normal" tt="none">
            Hi, {userName}
          </Text>
        </Menu.Label>
        <Menu.Divider />
        <Menu.Item
          component={Link}
          href="/settings"
          leftSection={<FaIcon icon="fa-gear" />}
        >
          Settings
        </Menu.Item>
        {isStaff && (
          <Menu.Item
            component={Link}
            href="/staff"
            leftSection={<FaIcon icon="fa-id-badge" />}
          >
            Staff
          </Menu.Item>
        )}
        <LogoutForm>
          <Menu.Item type="submit" leftSection={<FaIcon icon="fa-sign-out" />}>
            Sign out
          </Menu.Item>
        </LogoutForm>
      </Menu.Dropdown>
    </Menu>
  )
}

export const UserMenu = observer(function UserMenu() {
  const { user, socketManager } = useMst()
  const isOnline = socketManager.status === 'connected'

  return (
    <UserMenuBase
      avatarUrl={user.avatarUrl}
      isOnline={isOnline}
      isStaff={user.staff}
      userName={user.name}
    />
  )
})
