import {
  ActionIcon,
  Group,
  Indicator,
  SegmentedControl,
  Tooltip,
} from '@mantine/core'
import { useQueryClient } from '@tanstack/react-query'
import { observer } from 'mobx-react-lite'

import { FaIcon } from '@components/FaIcon'
import { useMst } from '@hooks'

export const SnapshotFilter = observer(function HistoryFilter({
  scriptId,
}: {
  scriptId: string
}) {
  const queryClient = useQueryClient()
  const {
    clearCachedData,
    fetchSnapshots,
    filter,
    hasNewer,
    setFetching,
    scriptQueryKey,
    setFilter,
    snapshotQueryKey,
  } = useMst().view.snapshotLand

  // when new snapshots are available and user clicks
  // the refresh button, we clear the old cache and start at the top
  const refreshHistory = async () => {
    clearCachedData()

    setFetching(true)
    await queryClient.invalidateQueries({
      queryKey: scriptQueryKey(scriptId),
    })

    // just in case its active
    await queryClient.invalidateQueries({
      queryKey: snapshotQueryKey({
        snapshotId: 'current',
        scriptId,
      }),
    })
    setFetching(false)

    await fetchSnapshots(scriptId)
  }

  return (
    <Group gap={4} wrap="nowrap">
      <Tooltip label="Refresh snapshots" position="bottom">
        <ActionIcon variant="subtle" onClick={refreshHistory} color="dark">
          <Indicator color="red" size={6} offset={-1} disabled={!hasNewer}>
            <FaIcon
              icon="fa-arrow-rotate-right"
              style={{ transform: 'rotate(30deg)' }}
            />
          </Indicator>
        </ActionIcon>
      </Tooltip>
      <SegmentedControl
        style={{ flexShrink: 0 }}
        value={filter}
        onChange={(value) => {
          setFilter(value === 'manual' ? 'manual' : 'all')
        }}
        size="xs"
        data={[
          {
            value: 'all',
            label: 'All',
          },
          {
            value: 'manual',
            label: 'Manual',
          },
        ]}
      />
    </Group>
  )
})
