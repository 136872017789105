import { useMutation } from '@tanstack/react-query'

import { useMst } from '@state'

export function useEmail(kind: 'mfa-added' | 'mfa-removed') {
  const { scrapi } = useMst()

  // we use `mutate` since it supports a "fire and forget" approach
  const { mutate } = useMutation({
    mutationFn: () => {
      switch (kind) {
        case 'mfa-added':
          return scrapi.emails.mfaAddPhone({ body: {} })
        case 'mfa-removed':
          return scrapi.emails.mfaRemovePhone({ body: {} })
      }
    },
  })

  return {
    send: mutate,
  }
}
