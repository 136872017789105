import {
  Breadcrumbs as BreadcrumbsComponent,
  Space,
  Title,
} from '@mantine/core'

import { NavAnchor, NavAnchorHref } from '@components/NavAnchor'

type Crumb = { text: string; url?: NavAnchorHref }

export const Breadcrumbs = ({ crumbs = [] }: { crumbs?: Crumb[] }) => {
  const allCrumbs: Crumb[] = [{ text: 'Staff Zone', url: '/' }, ...crumbs]

  const links = allCrumbs.map((c, idx) => {
    const key = `${idx}-${c.text}`
    const title = (
      <Title order={4} key={key}>
        {c.text}
      </Title>
    )
    return c.url ? (
      <NavAnchor href={c.url} key={key}>
        {title}
      </NavAnchor>
    ) : (
      title
    )
  })

  return (
    <>
      <BreadcrumbsComponent>{links}</BreadcrumbsComponent>
      <Space h={10} />
    </>
  )
}
