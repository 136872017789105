import React from 'react'

import {
  Button,
  Card,
  Group,
  Stack,
  Text,
  TextInput,
  Title,
  type TitleProps,
} from '@mantine/core'
import { observer } from 'mobx-react-lite'

import { FaIcon } from '@components/FaIcon'
import {
  showAlert,
  showDisablePhoneAuthModal,
  showEnablePhoneAuthModal,
  showVerifyPasswordModal,
} from '@components/Modals'
import { FormError } from '@components/Onboarding/FormErrors'
import { PasswordInput, useStrongPasswordForm } from '@components/PasswordInput'
import { Toast } from '@components/Toast'
import { useMst } from '@state'
import * as formUtil from '@util/formUtil'

type FormValues = { newPassword: string }

export const Security = observer(function Security() {
  const { view, authManager, user } = useMst()
  const [isEditingPassword, setIsEditingPassword] = React.useState(false)
  const { form, passwordStrength } = useStrongPasswordForm<FormValues>({
    passwordField: 'newPassword',
    validateInputOnBlur: true,
    initialValues: { newPassword: '' },
    validate: { newPassword: formUtil.validateNotEmpty },
  })

  const handleSubmitPassword = async ({ newPassword }: FormValues) => {
    const result = await authManager.stytch.client.resetPassword(newPassword)
    const title = result.success ? 'Success' : 'Error'
    const children = result.success ? (
      'Your password has been changed'
    ) : (
      <Toast
        type="error"
        dismissable={false}
        message={<FormError code={result.code} />}
      />
    )

    showAlert({ title, children })
    setIsEditingPassword(false)
    form.reset()
  }

  const { mfaEnabled, stytchUser } = authManager.stytch

  return (
    <Stack miw={500} pl={30} pr={30} gap={5}>
      <Title fz={30}>Security</Title>
      <Subtitle mb={10}>Password</Subtitle>
      <Card mb="xl">
        {stytchUser && isEditingPassword ? (
          <form onSubmit={form.onSubmit(handleSubmitPassword)}>
            <Group align="center" justify="space-between" mih={130}>
              <Group>
                <TextInput
                  readOnly
                  hidden
                  display="none"
                  value={user.email}
                  aria-label="Email"
                  autoComplete="username"
                />
                <PasswordInput
                  style={{ width: 280 }}
                  label="New password"
                  autoComplete="new-password"
                  strengthInfo={passwordStrength}
                  {...form.getInputProps('newPassword')}
                />
              </Group>
              <Group gap={2}>
                <Button disabled={!form.isValid()} type="submit">
                  Save changes
                </Button>
                <Button
                  variant="subtle"
                  ms="auto"
                  onClick={() => setIsEditingPassword(false)}
                >
                  Cancel
                </Button>
              </Group>
            </Group>
          </form>
        ) : (
          <Group align="center" justify="space-between">
            <Group align="flex-start">
              <FaIcon icon="fa-key" size="xl" />
              <Title order={3}>••••••••••••••••</Title>
            </Group>
            <Button
              variant="subtle"
              ms="auto"
              onClick={() => {
                stytchUser
                  ? setIsEditingPassword(true)
                  : showVerifyPasswordModal({
                      onVerified: () => {
                        setIsEditingPassword(true)
                      },
                    })
              }}
            >
              Edit
            </Button>
          </Group>
        )}
      </Card>
      {view.hasMFA && (
        <>
          <Subtitle>Two-factor authentication</Subtitle>
          <Group wrap="nowrap">
            <Text component="p" mb="sm">
              Add an additional layer of security for your account by requiring
              an additional check when signing in.
            </Text>
          </Group>
          <Card>
            <Group align="center" justify="space-between">
              <Group>
                <FaIcon icon="fa-message-sms" size="xl" />
                <Stack gap={5}>
                  <Text fw="bold">SMS / text message</Text>
                  <Text>
                    {mfaEnabled
                      ? 'You will receive a one-time code through your phone'
                      : 'Receive one-time codes through your cell phone'}
                  </Text>
                </Stack>
              </Group>
              {stytchUser ? (
                <Group>
                  <Text c={mfaEnabled ? 'green' : 'gray'}>
                    {mfaEnabled ? 'Enabled' : 'Not enabled'}
                  </Text>
                  <Button
                    variant="subtle"
                    ms="auto"
                    onClick={
                      mfaEnabled
                        ? showDisablePhoneAuthModal
                        : showEnablePhoneAuthModal
                    }
                  >
                    {mfaEnabled ? 'Disable' : 'Enable'}
                  </Button>
                </Group>
              ) : (
                <Button
                  variant="subtle"
                  ms="auto"
                  onClick={() => showVerifyPasswordModal()}
                >
                  Manage
                </Button>
              )}
            </Group>
          </Card>
        </>
      )}
    </Stack>
  )
})

function Subtitle(props: TitleProps) {
  return <Title order={2} size="lg" ff="inherit" {...props} />
}
