import NiceModal from '@ebay/nice-modal-react'
import { Space, Stack, Text } from '@mantine/core'
import { useLocation } from 'wouter'

import {
  ModalShell,
  showAsyncConfirmModal,
  useModalControls,
} from '@components/Modals'
import { OutsideAnchor } from '@components/OutsideAnchor'
import { useMst } from '@state'

export const WHATS_NEW_URL = 'https://www.scripto.live/whats-new'

export const offerUpdate = (version?: string) =>
  showAsyncConfirmModal({
    title: 'Update available',
    children: (
      <Stack gap={10}>
        <Space />
        <Text>
          We&apos;ve updated Scripto! Please refresh to get the latest and
          greatest.
        </Text>
        <OutsideAnchor href={WHATS_NEW_URL}>
          {'Learn more' + (version ? ` about ${version}` : '')}
        </OutsideAnchor>
        <Space />
      </Stack>
    ),
    errorMessage: 'Error reloading application',
    confirmLabel: 'Refresh',
    cancelLabel: 'Not Now',
    size: 'sm',
    onConfirm: () => Promise.resolve(window.location.reload()),
  })

interface WhatsNewProps {
  onDismiss?: Callback
}

export const WhatsNew = (props: WhatsNewProps) => {
  const [, setLocation] = useLocation()
  const controls = useModalControls()
  const { view, user } = useMst()

  const handleClose = () => {
    props.onDismiss?.()
    user.acknowledgeWhatsNewModal()
    controls.onClose()
  }

  const handleConfirm = () => {
    setLocation('/settings/security')
    handleClose()
  }

  // dont show the new bling more than once
  if (!view.showWhatsNewModal) return null

  return (
    <ModalShell
      title="New security feature 🔐"
      onConfirm={handleConfirm}
      confirmLabel="Turn it on"
      cancelLabel="Skip it"
      size={350}
      opened={controls.opened}
      onClose={handleClose}
    >
      <Stack px={5} pt={5} pb={10}>
        <Text>
          You can now enable two-factor authentication to add an additional
          layer of security to your account.
        </Text>
      </Stack>
    </ModalShell>
  )
}

export const WhatsNewModal = NiceModal.create(WhatsNew)
export const showWhatsNewModal = (onDismiss?: () => void) =>
  NiceModal.show(WhatsNewModal, { onDismiss })
