import React from 'react'

import { Flex, Stack } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import cn from 'classnames'
import { observer } from 'mobx-react-lite'

import { DevToolbar, EditorToolbar } from '@components/EditorToolbar'
import { GutterComments } from '@components/GutterComments'
import { GutterLeft } from '@components/GutterLeft'
import { ScriptMiniHeader } from '@components/MiniHeader'
import { PrompterView } from '@components/PrompterView'
import { ScriptStatusBar } from '@components/ScriptStatusBar'
import { HyperlinkPopover } from '@components/SelectionPopovers'
import { ScriptToastHost } from '@components/Toast'
import { useNavigation } from '@hooks'
import { InlineInkStyles } from '@ink/components'
import { ILoadedScript, useMst } from '@state'
import { scrollToBlock } from '@util/scrolling'

import { CommentsPanel } from './CommentsPanel'
import { InlineStyles } from './InlineStyles'
import { ScriptWrapper } from './ScriptWrapper'
import { SnapshotsPanel } from './SnapshotsPanel'

import styles from './Script.module.scss'

export const Script = observer(function Script({
  script,
}: {
  script: ILoadedScript
}) {
  const { currentInkProject, currentOrg, socketManager, user, view, location } =
    useMst()

  const { removeBlockParam } = useNavigation()
  const pageless = user.prefs.pageless || script.isInk
  const noWrap = script.isInk && !user.inkPreferences?.lineWrap
  const liveEditorView = script.pmEditor.editorManager?.view

  const isCreatingComment = script.pmEditor.hasUnsavedComment
  const [pmHost, pmHostRef] = React.useState<HTMLDivElement | null>(null)
  // launching the editor is async and we need to know when it's done, but
  // not trigger extra renders, so... use a ref
  const pmViewReadyRef = React.useRef(false)
  // when this is set, we want to scroll to it as soon as the editor is
  // ready and then clear it.
  const targetBlockId = location.getQueryParam('block')
  const [
    snapshotsPanelIsOpen,
    { toggle: toggleSnapshotsPanel, close: closeSnapshotsPanel },
  ] = useDisclosure(false)

  // don't show script until we have the right styles loaded. This hidden flag
  // ONLY makes the script invisible, doesn't hide any error toasts/loaders
  const hideScript = !script
  const classes = cn(styles.pmHostWrapper, {
    [styles.safelyHidden]: hideScript,
    [styles.disconnected]:
      !socketManager.connected || script.syncStatus.isTooStaleForSafety,
    [styles.isCreatingComment]: isCreatingComment,
    [styles.emptySelection]: !!script.pmEditor.selection?.empty,
    'is-empty-prose-doc': script.pmEditor.docInfo?.empty,
    [styles.showLayoutLines]: script.showLayoutLines,
  })

  // if you land on this page with a block param (ie: ?block=<blockId>) then once
  // the script is ready, we remove the query param from the URL and scroll to the block
  React.useEffect(() => {
    if (pmViewReadyRef.current && targetBlockId) {
      scrollToBlock(targetBlockId)
      removeBlockParam()
    }
  })

  React.useLayoutEffect(() => {
    if (!(pmHost && liveEditorView)) {
      pmViewReadyRef.current = false
    }
    if (pmHost) {
      if (!liveEditorView || liveEditorView.dom.parentNode !== pmHost) {
        script.updateSelfFromEditor()
        script.launchEditor(pmHost).then(() => {
          pmViewReadyRef.current = true
        })
      }
    } else if (!pmHost && liveEditorView) {
      script.tearDownEditor()
    }
  })

  // When switching view modes or view sizes, trigger prosemirror re-render
  // so things like avatar and comment positioning get updated
  React.useEffect(() => {
    script.pmEditor.rerender()
  }, [
    view.dimensions.scriptScroller.height,
    view.dimensions.scriptScroller.width,
    view.editorZoom,
    pageless,
    noWrap,
    script.pmEditor,
  ])

  return (
    <Stack gap={0}>
      {script.isInk && currentInkProject && (
        <InlineInkStyles
          messages={currentInkProject.compilerMessagesForScript(script.id)}
        />
      )}
      <InlineStyles script={script} />
      <ScriptMiniHeader />
      {currentOrg && (
        <EditorToolbar
          script={script}
          org={currentOrg}
          toggleSnapshotsPanel={toggleSnapshotsPanel}
        />
      )}
      <DevToolbar script={script} />
      <Flex h="100%" styles={{ root: { overflow: 'hidden' } }}>
        <ScriptWrapper
          pageCount={script.pageCount ?? 1}
          pageless={pageless}
          noWrap={noWrap}
          zoomLevel={view.editorZoom}
          blockFormats={script.blockFormats}
          paginationType={script.paginationType}
          pageLayout={script.pageLayout}
          showMarginRuler={user.prefs.showMarginRuler}
          fontCode={script.fontCode}
        >
          <div id="pm-host-wrapper" className={classes}>
            <div className="c-editor">
              <div
                className="c-editor__gutterleft"
                id="editor-gutter-left"
              ></div>
              <div className="c-editor__overlay"></div>
              <div className="l-box l-box--column c-editor-banner">
                <div id="editor" ref={pmHostRef}></div>
              </div>
              <div
                id="editor-gutter-right"
                className="c-editor__gutterright"
              ></div>
              <GutterLeft script={script} />
              {script.canAddComments && <GutterComments script={script} />}
              <div data-blurred-cursor className={styles.blurredCursor} />
            </div>
          </div>
          {script.showPrompterView && <PrompterView />}
          {!script.isInk && <HyperlinkPopover />}
        </ScriptWrapper>
        <CommentsPanel script={script} />
        <SnapshotsPanel
          scriptId={script.id}
          isOpen={snapshotsPanelIsOpen}
          onClose={closeSnapshotsPanel}
        />
      </Flex>
      <ScriptStatusBar />
      <ScriptToastHost />
    </Stack>
  )
})
