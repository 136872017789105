import {
  ActionIcon,
  Checkbox,
  Code,
  Group,
  JsonInput,
  Stack,
  Text,
} from '@mantine/core'
import { useQueryClient } from '@tanstack/react-query'

import { FaIcon, SmartFaIcon } from '@components/FaIcon'
import { useMst, useStaffFormat } from '@hooks'

import { Breadcrumbs } from '../Breadcrumbs'

import styles from './FormatDetail.module.scss'

export const FormatDetail = ({ params }: { params: { formatId: string } }) => {
  const mst = useMst()
  const { formatQuery } = useStaffFormat(params.formatId)
  const queryClient = useQueryClient()
  const { isPending, isError, data } = formatQuery

  if (isPending) return <div>loading format</div>
  if (isError) return <div>error fetching format</div>

  const { id } = data

  const updateMetadata = async (body: { active?: boolean; notes?: string }) => {
    await mst.scrapi.staff.updateFormat({ params: { id }, body })
    queryClient.invalidateQueries({ queryKey: ['staff.getFormat', id] })
  }

  const updateNotes = async () => {
    const oldNotes = data.notes
    const notes = prompt(`New notes`, oldNotes)
    if (notes === null) return
    await updateMetadata({ notes })
  }

  return (
    <Stack>
      <Breadcrumbs
        crumbs={[{ text: 'Formats', url: '/formats' }, { text: id }]}
      />
      <Text>
        Id: <Code>{id}</Code>
      </Text>
      <Text>
        User facing name: <Code>{data.name}</Code>
      </Text>
      <Text>
        User facing description: <Code>{data.description}</Code>
      </Text>
      <Group>
        <Text>Icon:</Text>
        <SmartFaIcon icon={data.icon} fallback={<div>icon</div>} />
      </Group>
      <Checkbox
        label="Active:"
        labelPosition="left"
        checked={data.active}
        onChange={() => updateMetadata({ active: !data.active })}
      />
      <Group>
        <Text>
          Notes: <Code>{data.notes}</Code>
        </Text>
        <ActionIcon color="purple" onClick={updateNotes}>
          <FaIcon size="12" icon="fa-pencil" />
        </ActionIcon>
      </Group>
      <Text>
        Workspace assignments: <Code>{data.workspaceCount}</Code>
      </Text>
      <Text>
        Replaced by: <Code>{data.replacedById}</Code>
      </Text>
      <Text>Definition:</Text>
      <JsonInput
        classNames={{ input: styles.input }}
        w={500}
        autosize
        disabled
        value={JSON.stringify(data.definition, null, 2)}
      />
    </Stack>
  )
}
